.sigCanvas{
  background-image: url(./sig_plate.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.wUnsetMax{
  max-width: unset!important;
}

.wUnset{
  width: unset!important;
}