
.aboutUs .title {
  text-align: center;
}
.aboutUs .title p {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2vw;
  font-size: 3vw;
  margin-bottom: -0.8vw;
}
@media (max-width: 576px) {
  .aboutUs .title p {
    font-size: 1.6em;
    margin-bottom: -8px;
  }
}
@media (min-width: 1801px) {
  .aboutUs .title p {
    font-size: 3.5rem;
  }
}
.aboutUs .title span {
  font-size: 1.5vw;
  font-family: "Noto Sans JP";
  font-weight: bold;
  letter-spacing: 0;
}
@media (max-width: 576px) {
  .aboutUs .title span {
    font-size: 0.8em;
  }
}
@media (min-width: 1801px) {
  .aboutUs .title span {
    font-size: 1.75rem;
  }
}
.aboutUs .about-card {
  background-color: #fff;
  padding: 5vw 5vw;
  border-radius: 3vw;
  /* margin-top: 8%; */
}
@media (max-width: 576px) {
  .aboutUs .about-card {
    padding: 8vw;
  }
}
.aboutUs .about-card label {
  font-size: 1.4vw;
  font-family: "Noto Sans JP";
  font-weight: 500;
  color: #021b48;
  letter-spacing: 2px;
}
@media (max-width: 576px) {
  .aboutUs .about-card label {
    font-size: 0.9rem;
    color: #5470a5;
  }
}
@media (min-width: 1801px) {
  .aboutUs .about-card label {
    font-size: 1.45rem;
  }
}
.aboutUs .about-card p {
  font-family: "Noto Sans JP";
  font-weight: 400;
  font-size: 1.2vw;
  color: #021b48;
}
@media (max-width: 576px) {
  .aboutUs .about-card p {
    font-size: 1rem;
  }
}
@media (min-width: 1801px) {
  .aboutUs .about-card p {
    font-size: 1.45rem;
  }
}
.aboutUs .about-card .bb {
  margin-top: 1%;
  padding: 1.5% 0%;
  border-bottom: 1px solid #021b48;
}
.aboutUs .about-card .bb:last-child {
  border: none;
}
@media (max-width: 576px) {
  .aboutUs .about-card .bb {
    border: none;
  }
}

.cat {
  width: 20%;
  margin-bottom: -1%;
  position: relative;
  z-index: 2;
}

@media (max-width: 576px) {
  .cat {
    width: 30%;
  }
}
