.ckc {
  padding-top: 11.81vw;
}
@media (max-width: 576px) {
  .ckc {
    padding-top: calc(50px + 15vw);
  }
}
.ckc .ckcCard {
  background-color: #fff;
  border: calc(2px + 0.15vw) solid #021b48;
  border-top-right-radius: 4vw;
  border-bottom-left-radius: 4vw;
  padding: 0vw 2vw 4vw 2vw;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.ckc .ckcCard .infoContainer {
  border: calc(1px + 0.15vw) solid #021b48;
  border-radius: 1.5vw;
  padding: 1vw 2vw;
  text-align: center;
  width: min-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin-top: -7vw;
  margin-bottom: 5vw;
  position: relative;
  background-color: #ffe36a;
  box-shadow: 10px 10px 0px #ffe36a, inset 10px 10px 0px #fff;
  z-index: 1;
}
@media (min-width: 1801px) {
  .ckc .ckcCard .infoContainer {
    margin-top: -100px;
    margin-bottom: 80px;
  }
}
@media (max-width: 576px) {
  .ckc .ckcCard .infoContainer {
    box-shadow: none;
    padding: unset;
    border: none;
    border-radius: 3vw;
    margin-top: -60px;
    width: 191px;
    height: 114px;
    transform: translate(-55%, -1%);
    align-self: unset;
    left: 50%;
  }
}
.ckc .ckcCard .infoContainer .whats {
  position: absolute;
  height: 50%;
  top: -14%;
  left: -11%;
}
@media (max-width: 576px) {
  .ckc .ckcCard .infoContainer .whats {
    height: 52%;
    top: -31%;
    left: -21%;
  }
}
.ckc .ckcCard .infoContainer .background {
  height: 11vw;
  width: 24vw;
  background-color: #ffe36a;
  position: absolute;
}
.ckc .ckcCard .infoContainer .info {
  display: flex;
  justify-content: center;
}
.ckc .ckcCard .infoContainer p {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.4vw;
  font-size: 4vw;
  margin-bottom: -1.2vw;
}
@media (min-width: 1801px) {
  .ckc .ckcCard .infoContainer p {
    font-size: 4.5em;
  }
}
@media (max-width: 576px) {
  .ckc .ckcCard .infoContainer p {
    font-size: 2.3em;
  }
}
.ckc .ckcCard .infoContainer img {
  height: 6.5vw;
}
@media (min-width: 1801px) {
  .ckc .ckcCard .infoContainer img {
    height: 100px;
  }
}
@media (max-width: 576px) {
  .ckc .ckcCard .infoContainer img {
    height: 40px;
    margin-top: 5px;
  }
}
.ckc .ckcCard .infoContainer span {
  font-size: 1.5vw;
  font-family: "Noto Sans JP";
  font-weight: bold;
  letter-spacing: 0;
  white-space: nowrap;
}
@media (min-width: 1801px) {
  .ckc .ckcCard .infoContainer span {
    font-size: 2em;
  }
}
@media (max-width: 576px) {
  .ckc .ckcCard .infoContainer span {
    font-size: 1em;
    letter-spacing: 0.5vw;
  }
}
.ckc .ckcCard .mobileborder {
  display: none;
}
@media (max-width: 576px) {
  .ckc .ckcCard .mobileborder {
    display: block;
    position: absolute;
    width: 191px;
    height: 114px;
    border: calc(1px + 0.3vw) solid #021b48;
    border-radius: 3vw;
    top: -54px;
    left: 50%;
    transform: translate(-51%, 2%);
  }
}
.ckc .ckcCard .ckImg {
  height: auto;
  border-top-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
}
@media (max-width: 576px) {
  .ckc .ckcCard .ckImg {
    border-top-left-radius: 10vw;
    border-bottom-right-radius: 10vw;
  }
}
.ckc .ckcCard .ckText {
  font-family: "Zen Kaku Gothic New", sans-serif;
  text-align: center;
  font-size: 1.2vw;
  font-weight: 500;
  color: #021b48;
}
@media (min-width: 1801px) {
  .ckc .ckcCard .ckText {
    font-size: 1.3em;
  }
}
@media (max-width: 576px) {
  .ckc .ckcCard .ckText {
    font-size: 1em;
    font-family: "Noto Sans JP";
  }
}

.ml_n3pP {
  margin-left: -3%;
}

.art {
  position: relative;
  top: -11%;
  left: -10%;
  width: 40%;
  transform: rotate(21deg);
}

@media (max-width: 576px) {
  .art {
    top: -2rem;
    left: -10%;
    transform: rotate(0deg);
    width: 50%;
  }
}
