.privacyPolicy {
  /* padding-top: 15.81vw; */
  color: #021b48;
}
@media (max-width: 576px) {
}
.privacyPolicy .title {
  font-family: "Noto Sans JP";
  font-weight: bold;
  font-size: 2.2vw;
  text-align: center;
}
@media (max-width: 576px) {
  .privacyPolicy .title {
    font-size: 1.5em;
  }
}
.privacyPolicy .subtextCol {
  margin-top: -2.2vw;
}
@media (max-width: 576px) {
  .privacyPolicy .subtextCol {
    margin-top: 0px;
  }
}
.privacyPolicy .subtextCol p {
  text-align: end;
  margin: 0;
  font-family: "Noto Sans JP";
  font-weight: 400;
  font-size: 1rem;
}
@media (max-width: 576px) {
  .privacyPolicy .subtextCol p {
    font-size: 0.7em;
  }
}
.privacyPolicy .subtextCol p:nth-child(1) {
  margin-bottom: 1vw;
}
@media (max-width: 576px) {
  .privacyPolicy .subtextCol p:nth-child(1) {
    margin-bottom: 10px;
  }
}

.m-card {
  background-color: #fff;
  border-radius: 3vw;
  padding: 3vw 4vw 4vw 4vw;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
}

.privacyPolicy .m-card {
  margin-top: 5%;
  padding: 4vw 5vw 0 5vw;
}
@media (max-width: 576px) {
  .privacyPolicy .m-card {
    padding: 30px 25px 50px 25px;
  }
}
.privacyPolicy .m-card p {
  font-family: "Noto Sans JP";
  /* font-size: 1rem; */
  font-weight: 400;
  /* line-height: 2vw; */
  text-align: justify;
}
@media (max-width: 576px) {
  /* .privacyPolicy .m-card p {
    font-size: 0.75em;
    line-height: 1.7em;
    margin-bottom: 0 !important;
  } */

  .privacyPolicy .m-card {
    font-size: 0.84rem;
  }
}
.privacyPolicy .m-card .pink-card {
  margin-top: 4%;
  width: 100%;
  padding: 2vw;
  color: #fff;
  background-color: #fb96b4;
  border-bottom-left-radius: 3vw;
  border-top-right-radius: 3vw;
  font-family: "Noto Sans JP";
}
@media (max-width: 576px) {
  .privacyPolicy .m-card .pink-card {
    font-size: 0.9em !important;
    padding: 9vw 6vw;
    margin-top: 15%;
    border-bottom-left-radius: 6vw;
    border-top-right-radius: 6vw;
  }
}
.privacyPolicy .m-card .pink-card .address {
  margin-left: 8%;
}
@media (max-width: 576px) {
  .privacyPolicy .m-card .pink-card .address {
    font-size: 0.75em;
    margin-left: 10px;
  }
}
@media (max-width: 576px) {
  .privacyPolicy .m-card .pink-card p {
    font-weight: bold !important;
    margin-bottom: 0 !important;
  }
}
.topArt {
  position: absolute;
  width: 19%;
  top: 3%;
}

.bottomArt {
  position: absolute;
  width: 25%;
  bottom: -2%;
  right: 2%;
}

.cat {
  width: 20%;
  margin-left: -2vw;
  margin-top: 3%;

  margin-bottom: 4rem;
}

@media (max-width: 576px) {
  .topArt {
    width: 17%;
    top: 7%;
    left: -1%;
  }
  .bottomArt {
    bottom: 45%;
    right: 0;
  }
  .cat {
    margin-bottom: 0rem;
  }
}

ol {
  counter-reset: item;
  margin-left: 0;
  padding-left: 0;
}
ol li {
  display: block;
  margin-bottom: 0.5em;
  margin-left: 2em;
}
ol li::before {
  display: inline-block;
  content: "(" counter(item) ") ";
  counter-increment: item;
  width: 2em;
  margin-left: -1em;
}
