.bgPrimary {
  background: #f0ebe5;
}
.bgSecondary{
  background-color: #ffb6cf;
  position: absolute;
  height: 314px;
  width: 100%;
}
.plan{
  overflow: hidden;
}
.planHeader{
  background-color: aliceblue;
}
.planBody{
  background-color: white;
}
ul{
  padding-left: 0 !important;
}
ul>li{
  list-style: none;
}