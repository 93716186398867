:root {
  --cmn-bg: #dbdbdb;
  --cmn-ash-lite: #cfcfcf;
  --cmn-yollow-deep: #ff9428;
  --cmn-yollow: #f8d07d;
  --cmn-yollow-hover: #bda366;
  --cmn-yollow-lite: #fff1d6;
  --cmn-green-deep: #389038;
  --cmn-green: #c4ffcc;
  --cmn-blue: #c2ceff;
  --cmn-blue-deep: #4cb2ff;
  --cmn-pink: #ee78b2;
  --cmn-red: #ff4848;
  --cmn-text-color-dark: #2b2b2b;
  --cmn-footer-color-dark153: #2B2B2B99;
  --cmn-text-color-ash: #4f4f4f;

  /* Colors from hosino san's cmyk template */
  --cmn-pastel-yellow: #FED858;
  --cmn-slate-green: #ADD997;
  --cmn-green-shade: #0A6635;
  --cmn-teal-green: #006633;
  --cmn-gray-light: #e8e8e8;
  --cmn-gray-shade: #4f4f4f;
  --cmn-gray-shade-100: #DEDEDE;
  --cmn-yellow-shade-100: #E5BF0C;
  --cmn-gainsboro-gray: #D3D3D3;
  --cmn-sky-blue: #4CA3FF;
  --cmn-golden-rod: #FAAF00;
  --cmn-red: #FF4848;
  --cmn-crimson: #FF5858;
  --cmn-pumpkin-orange: #FFBB15;
  --cmn-orange-shed: #FCA265;
  --cmn-mango-orange: #FF8528;
  --cmn-dusty-rose: #ED8099;
  --cmn-rose: #ED8099;
  --cmn-green-deep: #388038;
  --cmn-chartreuse: #ACDC00;
  --cmn-silver: #A4A4A4;
  --cmn-midnight-blue: #101C46;
  --cmn-peach-puff: #FFE2C7;
  --cmn-light-green: #BFFFB8;
  --cmn-periwinkle-blue: #BCE7FF;
  --cmn-orange-brown: #EB994F;

}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  /* position: fixed; */
  font-family: "Noto Sans JP", sans-serif !important;
  width: 100% !important;
  overflow-x: hidden !important;
  color: #101c46 !important;
}

.main {
  /* min-height: 100vh; */
  /* margin-top: -9.6vw; */
  background: #f0ebe5;
}

.bg-home {
  background: #f0ebe5;
}

.fs-10 {
  font-size: 0.75rem;
}
@media (max-width: 576px) {
  .main {
    /* margin-top: -24.6vw; */
  }
}

.bootstrap-off > * {
  all: unset !important;
}

.react-date-picker__wrapper {
  border: unset !important;
}
.react-date-picker__calendar {
  z-index: 99 !important;
}

.star-ratings {
  transform: translateY(-6px) !important;
}

.ql-editor {
  font-family: "Noto Sans JP", sans-serif !important;
}

.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 0 !important;
}
.ql-editor ol,
.ql-editor ul {
  padding-left: 0 !important;
}
.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 1em !important;
}
.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 2em !important;
}
.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 3em !important;
}
.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 4em !important;
}
.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 5em !important;
}
.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 6em !important;
}
.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 7em !important;
}
.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 8em !important;
}

.circle-marked {
  border: 1px solid black;
  border-radius: 50%;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
}

.v-write {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

.pdfobject-container {
  height: 100%;
}



.drag-list {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.drag-item {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  padding: 10px;
  margin-bottom: 8px;
  border-radius: 4px;
  user-select: none;
  transition: background-color 0.2s ease;

  
}

.drag-item:hover {
  background-color: #f0f0f0;
}

.drag-item:last-child {
  margin-bottom: 0;
}

.fit-width{
  width: fit-content;
  width: -moz-fit-content;
}

.h-min-100{
  min-height: 100% !important;
}

.z-index-5 {
  z-index: 5 !important;
}
