#sidebarWrapper {
  position: fixed;
  bottom: 0;
  height: calc(100vh - 58px);
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  overflow-y: scroll;
  width: 17rem;
}
#sidebarWrapper .sidebarHeading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}
#sidebarWrapper .listGroup {
  width: 17rem;
  font-size: 1.1rem;
}
#sidebarWrapper .listGroup .link i {
  width: 25px;
}
#sidebarWrapper .sidemenuactive {
  background-color: #dae0e5 !important;
}
#sidebarWrapper .listGroup .link:hover {
  background-color: #dae0e5 !important;
}

@media screen and (max-width: 820px) {
  .bgWrapper {
    position: fixed;
    display: none;
    z-index: 200;
    left: 0%;
    margin-top: -7px;
    width: 100%;
    height: calc(100% - 56px);
    transition: 2.5s ease;
    background: rgba(0, 0, 0, 0.3);
    bottom: 0;
  }

  #sidebarWrapper {
    position: fixed;
    z-index: 201;
    left: -100%;
    margin-top: -7px;
    height: calc(100% - 56px);
    transition: 0.8s ease;
  }

  .sidebarWrapperActive {
    left: 0!important;
  }

  .bgWrapperActive {
    position: fixed;
    z-index: 200;
    left: 0%;
    margin-top: -7px;
    width: 100%;
    height: calc(100% - 56px);
    transition: 2.5s ease;
    background: rgba(0, 0, 0, 0.3);
    display: block;
    bottom: 0;
  }
}

.avater{
  width: 60px;
  height: 60px;
  width: 60px;
  height: 60px;
  object-fit: cover;
}