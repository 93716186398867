/* .newsDetails {
    padding-top: 15.81vw;
    padding-bottom: 5vw;
    color: #021b48;
  }
  @media (max-width: 576px) {
    .newsDetails {
      padding-top: calc(100px + 0vw);
    }
  } */
.m-card {
  background-color: #fff;
  border-radius: 3vw;
  /* padding: 3vw 4vw 4vw 4vw; */
  display: flex;
  flex-direction: column;
}
.newsDetails .title {
  text-align: center;
}
.newsDetails .title p {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2vw;
  font-size: 3vw;
  margin-bottom: -1.2vw;
}
@media (max-width: 576px) {
  .newsDetails .title p {
    font-size: 1.6em;
    margin-bottom: -8px;
  }
}
.newsDetails .title span {
  font-size: 1.6vw;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: bold;
  letter-spacing: 0;
}
@media (max-width: 576px) {
  .newsDetails .title span {
    font-size: 0.8em;
  }
}
.newsDetails .m-card {
  /* padding: 0px 7vw 8vw 7vw; */
  margin-top: 5%;
}
