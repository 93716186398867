.mainBody {
  margin: 56px 10px 10px calc(17rem + 20px);
  padding-top: 10px;
}

.switch input:checked {
  background-color: #6c757d;
  border-color: #6c757d;
  
}

.switch input {
  width: 2.75em !important;
  height: 1.5em;
}

.switch input:focus {
  color: #fff;
  /* background-color: #5c636a;*/
  border-color: #565e64; 
  box-shadow: 0 0 0 0.25rem rgb(130 138 145 / 50%);

  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e") !important
}
.switch input:focus:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important
}


.switch input:hover {
  color: #212529;
}
@media screen and (max-width: 768px) {
  .mainBody {
    margin: 56px 15px 15px;
    padding-top: 10px;
  }
}
