.m-card {
  background-color: #fff;
  border-radius: 3vw;
  padding: 3vw 4vw 4vw 4vw;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
}

.personalinfo {
  /* padding-top: 6rem; */
  color: #021b48;
}
@media (max-width: 576px) {
  .personalinfo {
    /* padding-top: 50px; */
  }
}
.personalinfo .titleT {
  font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 2.2em;
    text-align: center;
    margin-bottom: 0 !important;
}
@media (max-width: 576px) {
  .personalinfo .titleT {
    font-size: 1.5em;
    
  }
}
.personalinfo .subtitleT {
  font-family: "Noto Sans JP";
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px !important;
    font-size: 1.5rem;
}
@media (max-width: 576px) {
  .personalinfo .subtitleT {
    font-size: 1rem;
    
  }
}
.personalinfo .m-card {
  text-align: justify;
}
@media (max-width: 576px) {
  .personalinfo .m-card {
    padding: 35px 15px;
  }
}
.personalinfo .m-card .title {
  font-family: "Noto Sans JP";
  font-weight: bold;
  text-align: center;
  font-size: 1.4rem;
}
@media (max-width: 576px) {
  .personalinfo .m-card .title {
    font-size: 0.85em;
  }
}
.personalinfo .m-card .marginTOP {
  margin-top: 10%;
}
.personalinfo .m-card .details {
  margin-top: 3%;
}
.personalinfo .m-card .details p {
  font-family: "Noto Sans JP";
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 0 !important;
}
@media (max-width: 576px) {
  .personalinfo .m-card .details p {
    font-size: 0.75em;
  }
}
.personalinfo .m-card .details ul {
  margin-left: 0%;
}
@media (max-width: 576px) {
  .personalinfo .m-card .details ul {
    margin-left: -14%;
    margin-bottom: 0 !important;
  }
}
.personalinfo .m-card .details ul li {
  font-family: "Noto Sans JP";
  font-weight: 400;
  font-size: 1rem;
  list-style-type: disc;
  list-style-position: inside;
  text-indent: oem;
  padding-left: 1em;
}
@media (max-width: 576px) {
  .personalinfo .m-card .details ul li {
    font-size: 0.8em;
  }
}
.personalinfo .m-card .details table {
  margin-top: 4%;
  margin-bottom: 6%;
  border-collapse: collapse;
  width: 100%;
}
@media (max-width: 576px) {
  .personalinfo .m-card .details table {
    margin-bottom: 18%;
  }
}
.personalinfo .m-card .details table colgroup col:nth-child(1) {
  width: 35%;
}
.personalinfo .m-card .details table colgroup col:nth-child(2) {
  width: 65%;
}
.personalinfo .m-card .details table tr {
  background-color: none;
  border: 1px solid #707070;
}
@media (max-width: 576px) {
  .personalinfo .m-card .details table tr {
    border-left: none;
    border-right: none;
  }
}
.personalinfo .m-card .details table tr th {
  font-family: "Noto Sans JP";
  font-weight: bold;
  font-size: 1.2vw;
  text-align: center;
  padding: 0.8vw;
}
@media (max-width: 576px) {
  .personalinfo .m-card .details table tr th {
    font-size: 0.95em;
    padding: 1.5vw;
    background-color: #efebe5;
  }
}
.personalinfo .m-card .details table tr th:nth-child(1) {
  border-right: 1px solid #707070;
}
.personalinfo .m-card .details table tr {
  border: 1px solid #707070;
  border-left: none;
  border-right: none;
}
@media (max-width: 576px) {
  .personalinfo .m-card .details table tr {
    border-left: none;
    border-right: none;
  }
}
.personalinfo .m-card .details table tr td {
  font-family: "Noto Sans JP";
  font-weight: 400;
  font-size: 1rem;
  text-align: start;
  padding: 0.6rem;
}
@media (max-width: 576px) {
  .personalinfo .m-card .details table tr td {
    padding: 1.5vw;
    font-size: 0.83em;
  }
}
.personalinfo .m-card .details table tr td:nth-child(1) {
  border-right: 1px solid #707070;
}
.personalinfo .m-card .details .stext {
  margin-left: 4%;
}
@media (max-width: 576px) {
  .personalinfo .m-card .details .stext {
    margin-left: 0%;
  }
}

.tables th {
  background-color: #efebe5;
}

.tables tr th:first-child {
  border-left: none;
}

.dog {
  position: absolute;
  right: 0;
  width: 15%;
  z-index: 4;
}
@media (max-width: 576px) {
  .dog {
    right: 2%;
    width: 17%;
    top: 1%;
  }
}
