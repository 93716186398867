.RatingProgressBar {
  width: 100%;
  background-color: #D3D3D3;
  border-radius: 0;
}

.RatingProgressBar .progress-bar {
  transition: width 0.5s ease;
}


@media (max-width: 576px) {
  .RatingProgressBar {
    height: 10px !important;
  }
}