.bgPink {
  background-color: #f394b2;
}

.bgGreen {
  background-color: #a2d7c6;
}

.bgOrange {
  background-color: #f69656;
}

.bgYellow {
  background-color: #fee57d;
}

.navbar {
  background-color: rgba(0, 0, 0, 0);
  padding-left: 13%;
  height: 11.81rem;
  z-index: 9999;
}
.pathBtn {
  font-size: 20px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: bold;
  color: #101c46;
  text-decoration: none;
  background-color: #fff;
  border-radius: 15px;
  text-align: center;
  padding: 0.6rem 0.6rem;
  min-width: 12rem;
  border: 2px solid #101c46;
  margin: 1rem;
  transition: .4s ease;
}

.pathBtn:hover {
  color: #fff;
  background-color: #6080bb;

}
@media (max-width: 576px) {
  .pathBtn {
    font-size: 1em;
    width: 200px;
    border-radius: 10px;
    padding: 2vw 1vw;
  }
  .navbar {
    height: 100px;
    padding: 0;
  }
}
.navbar .logo {
  width: 8.5rem;
  height: 8.155rem;
  margin-top: auto;
  margin-left: 0%;
}
@media (max-width: 576px) {
  .navbar .logo {
    width: 6.4rem;
    height: auto;
    margin-left: 17%;
    margin-top: 12%;
  }
}
.navbar .menu {
  display: flex;
  margin-right: 11rem;
}
.navbar .menu .menuLink {
  position: relative;
  margin: 0px calc(2px + 0.2rem);
  padding: calc(3px + 0.4rem) calc(6px + 0.6rem);
  border-radius: 1.2rem;
  height: fit-content;
  transition: 0.5s ease;
}
.navbar .menu .menuLink .c1 {
  display: flex;
  align-items: center;
}
.navbar .menu .menuLink .c1 img {
  height: 1.82rem;
}
.navbar .menu .menuLink .c1 span {
  margin-left: calc(4px + 0.8rem);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  color: #021b48;
}
.navbar .menu .menuLink .c1 .c2 {
  position: absolute;
  top: 2.8rem;
  visibility: hidden;
  opacity: 0;
  width: calc(100% + 0px);
  justify-content: center;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.5s ease;
  padding: 1.2rem 0;
  border-radius: 0rem 0rem 1.2rem 1.2rem;
}

.navbar .menu .menuLink:hover {
  border-radius: 1.2rem 1.2rem 0rem 0rem !important;
}
.navbar .menu .menuLink:hover .c1 .c2 {
  opacity: 1;
  visibility: visible;
}
.navbar .menu .menuLink .c1 .c2 .cbtn {
  background-color: #fff;
  text-decoration: none;
  color: #101c46;
  font-weight: bold;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 1rem;
  padding: 0.4rem 1.2rem;
  border-radius: 0.3rem;
}
.navbar .hamburger {
  background-color: #5470a5;
  height: 8rem;
  width: 9rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
  border-bottom-left-radius: 4rem;
}
@media (max-width: 576px) {
  .navbar .hamburger {
    height: 4.5rem;
    width: 5rem;
    border-bottom-left-radius: 2rem;
  }
}
.navbar .hamburger .menu__icon {
  height: 3.3rem;
  width: 3.5rem;
  position: absolute;
  left: 37%;
  top: 22%;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
@media (max-width: 576px) {
  .navbar .hamburger .menu__icon {
    height: 2rem;
    width: 2rem;
    left: 52%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.navbar .hamburger .menu__icon span {
  display: block;
  background: #eb9e5c;
  width: 100%;
  height: 0.25rem;
  margin-top: -2px;
  position: absolute;
  left: 0;
  top: 50%;
}
@media (max-width: 576px) {
  .navbar .hamburger .menu__icon span {
    height: 3px;
    margin-top: -2.5px;
  }
}
.navbar .hamburger .menu__icon:before,
.navbar .hamburger .menu__icon:after {
  content: "";
  display: block;
  background: #a2d7c6;
  width: 100%;
  height: 0.25rem;
  position: absolute;
  left: 0;
  transform-origin: center center;
  transform: rotate(0deg);
  transition: all 0.3s ease;
}
@media (max-width: 576px) {
  .navbar .hamburger .menu__icon:before,
  .navbar .hamburger .menu__icon:after {
    height: 3px;
  }
}
.navbar .hamburger .menu__icon:before {
  top: 20%;
  margin-top: -2px;
}
@media (max-width: 576px) {
  .navbar .hamburger .menu__icon:before {
    margin-top: -4px;
  }
}
.navbar .hamburger .menu__icon:after {
  bottom: 20%;
  margin-bottom: -2px;
  background-color: #fb96b4;
}
@media (max-width: 576px) {
  .navbar .hamburger .menu__icon:after {
    margin-bottom: -2px;
  }
}

.menuContainer {
  background-color: #879fce;
  width: 100%;
  min-height: 100%;
  position: relative;
  padding-top: calc(20px + 2rem);
  padding-bottom: calc(40px + 4rem);
  position: absolute;
  top: 0;
  max-height: fit-content;
  z-index: 9998;
}

.menuContainer .menuContainerPosition {
  position: relative;
  top: 6rem;
}

.menuContainer .modHr {
  background-color: #fff;
  height: 4px;
  margin: 4% 0;
  display: block;
}
@media (max-width: 576px) {
  .menuContainer .modHr {
    display: none;
  }
}
.menuContainer .logo {
  width: 8.5rem;
  height: 8.155rem;
  margin-top: auto;
}
@media (max-width: 576px) {
  .menuContainer .logo {
    width: 22.4rem;
    height: 21.5292rem;
    margin-top: 2%;
  }
}
.navbar .hamburgerOpen {
  background-color: #5470a5;
  height: 8rem;
  width: 9rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
  border-bottom-left-radius: 4rem;
}
@media (max-width: 576px) {
  .navbar .hamburgerOpen {
    height: 4.5rem;
    width: 5rem;
    border-bottom-left-radius: 2rem;
  }
}
.navbar .hamburgerOpen .menu__icon {
  height: 3.3rem;
  width: 3.5rem;
  position: absolute;
  left: 37%;
  top: 22%;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
@media (max-width: 576px) {
  .navbar .hamburgerOpen .menu__icon {
    height: 2rem;
    width: 2.5rem;
    left: 52%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.navbar .hamburgerOpen .menu__icon span {
  display: block;
  background: #a2d7c6;
  width: 100%;
  height: 0;
  margin-top: -2px;
  transform: rotate(45deg);
  position: absolute;
  left: 0;
  top: 50%;
}
@media (max-width: 576px) {
  .navbar .hamburgerOpen .menu__icon span {
    height: 3px;
    display: none;
  }
}
.navbar .hamburgerOpen .menu__icon:after {
  content: "";
  display: block;
  background: #eb9e5c;
  width: 100%;
  height: 0.25rem;
  position: absolute;
  left: 0%;
  top: 50%;
  transform-origin: center center;
  transform: rotate(-45deg);
  transition: all 0.3s ease;
}

.navbar .hamburgerOpen .menu__icon:before {
  content: "";
  display: block;
  background: #a2d7c6;
  width: 100%;
  height: 0.25rem;
  position: absolute;
  left: 0%;
  top: 50%;
  transform-origin: center center;
  transform: rotate(45deg);
  transition: all 0.3s ease;
}
@media (max-width: 576px) {
  .navbar .hamburgerOpen .menu__icon:after {
    height: 3px;
  }
}
/* .navbar .hamburgerOpen .menu__icon:before {
    top: 20%;
    margin-top: -2px;
  } */
@media (max-width: 576px) {
  .navbar .hamburgerOpen .menu__icon:before {
    margin-bottom: -2px;
    height: 3px;
  }
}
.navbar .hamburgerOpen .menu__icon:after {
  bottom: 20%;
  margin-bottom: -2px;
  background-color: #fb96b4;
}
@media (max-width: 576px) {
  .navbar .hamburgerOpen .menu__icon:after {
    margin-bottom: -2px;
  }
}
.menuContainer .items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.menuContainer .items .item1,
.menuContainer .items .item2,
.menuContainer .items .item3 {
  margin: 1.8rem;
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Zen Kaku Gothic New", sans-serif;
  justify-content: center;
  padding: 0.8rem;
  cursor: pointer;
}
@media (max-width: 576px) {
  .menuContainer .items .item1,
  .menuContainer .items .item2,
  .menuContainer .items .item3 {
    font-size: 0.9em;
    width: 280px;
    height: 65px;
    border-radius: 14px;
    flex-direction: row;
    justify-content: start;
    padding: 0.4rem;
    padding-left: 5%;
    margin: 9px;
  }

  .menuContainer .items .item1.active,
  .menuContainer .items .item2.active,
  .menuContainer .items .item3.active {
    height: auto;
    flex-direction: column;

  }

  .menuContainer .items .item1 .loginBtn,
  .menuContainer .items .item2 .loginBtn,
  .menuContainer .items .item3 .loginBtn {
    display: none;
    background-color: white;
    /* padding: 5px 21px; */
    width: 98%;
    border-radius: 4px;
    justify-content: center;
    margin: 10px;
    transform: translateX(-5px);
  }

  .menuContainer .items .item1.active .loginBtn,
  .menuContainer .items .item2.active .loginBtn,
  .menuContainer .items .item3.active .loginBtn {
    display: flex;
  }
  .menuContainer .items .item1 .loginBtn a,
  .menuContainer .items .item2 .loginBtn a,
  .menuContainer .items .item3 .loginBtn a {
    color: #021b48;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin: 5px;
  }
}
.menuContainer .items .item1 p,
.menuContainer .items .item2 p,
.menuContainer .items .item3 p {
  margin: 0;
  color: #101c46;
  text-align: center;
  font-size: 2.2rem;
  font-weight: 600;
  margin-left: 0;
}
@media (max-width: 576px) {
  .menuContainer .items .item1 p,
  .menuContainer .items .item2 p,
  .menuContainer .items .item3 p {
    font-size: 1.3em;
    letter-spacing: 0.2em;
    margin-left: 10px;
  }
}
.menuContainer .items .item1 img,
.menuContainer .items .item2 img,
.menuContainer .items .item3 img {
  height: calc(25px + 2.5rem);
  justify-self: center;
  margin-bottom: 0.4rem;
}
@media (max-width: 576px) {
  .menuContainer .items .item1 img,
  .menuContainer .items .item3 img {
    height: 35px;
    margin-bottom: 0;
  }

  .menuContainer .items .item2 img {
    height: 30px;
  }
}
.menuContainer .items .item1 {
  background-color: #a1d7c6;
}
.menuContainer .items .item1:hover {
  background-color: #a0e5d2;
  border: calc(2px + 0.2rem) solid #63dbba;
  transition: all 0.6s ease;
}
.menuContainer .items .item2 {
  background-color: #f394b2;
}
.menuContainer .items .item2:hover {
  border: calc(2px + 0.2rem) solid #ff8fb3;
  background-color: #ffb6cf;
  transition: all 0.6s ease;
}
.menuContainer .items .item3 {
  background-color: #f69656;
}
.menuContainer .items .item3:hover {
  border: calc(2px + 0.2rem) solid #ff914e;
  background-color: #ffbc92;
  transition: all 0.6s ease;
}
.menuContainer .items .item2 p {
  font-size: 1.8rem;
  letter-spacing: -3px;
}
.menuContainer .itemLinks {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 3%;
}
.menuContainer .itemLinks a {
  width: 15rem;
  margin: 1.8rem;
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: none;
  color: #101c46;
}
@media (max-width: 576px) {
  .menuContainer .items .item2 p {
    font-size: 1.1rem;
  }
  .menuContainer .itemLinks a {
    width: 240px;
    font-size: 1em;
    text-align: start;
    margin: 8px;
  }
}
.menuContainer .itemLinks a:hover {
  color: #fff;
}
.menuContainer .link2 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  text-decoration: none;
  color: #021b48;
  margin-left: 2rem;
  margin-top: 0;
  font-size: 1.2rem;
  position: relative;
}
@media (max-width: 576px) {
  .menuContainer .link2 {
    font-size: 0.8rem;
    font-weight: 400;
    margin-top: 5px;
    display: block;
    width: 240px;
    margin: 5px auto;
  }
}

.menuContainer .link2::after {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 0%;
  border-bottom: 1px solid #021b48;
  left: 0;
  transition: 0.3s ease-in;
}

.menuContainer .link2:hover::after {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 100%;
  border-bottom: 1px solid #021b48;
  left: 0;
}
