.translaw {
  padding-top: 1.81vw;
  color: #021b48;
}
@media (max-width: 576px) {
  .translaw {
    margin-top: 30px;
  }
} 



.m-card {
  background-color: #fff;
  border-radius: 3vw;
  padding: 3vw 4vw 4vw 4vw;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
}

.translaw .title {
  font-family: "Noto Sans JP";
  font-weight: bold;
  font-size: 2.2vw;
  text-align: center;
}
@media (max-width: 576px) {
  .translaw .title {
    font-size: 1.5em;
  }
}
.translaw .m-card {
  padding: 4vw 5vw 5vw 5vw;
  margin-top: 5%;
}
@media (max-width: 576px) {
  .translaw .m-card {
    padding: 8vw 7vw 5vw 7vw;
  }
}
.translaw .m-card label {
  font-size: 1.3vw;
  font-family: "Noto Sans JP";
  font-weight: bold;
  color: #021b48;
}
@media (max-width: 576px) {
  .translaw .m-card label {
    font-size: 0.8rem;
  }
}
@media (min-width: 1801px) {
  .translaw .m-card label {
    font-size: 1.45rem;
  }
}
.translaw .m-card p {
  font-family: "Noto Sans JP";
  font-weight: 400;
  font-size: 1.1vw;
  color: #021b48;
}
@media (max-width: 576px) {
  .translaw .m-card p {
    font-size: 0.82rem;
  }
}
@media (min-width: 1801px) {
  .translaw .m-card p {
    font-size: 1.45rem;
  }
}
.translaw .m-card .bb {
  /* margin-top: 1%;
  margin-bottom: 1%; */
  padding: 2.5% 0%;
  border-bottom: 1px solid #021b48;
}
.translaw .m-card .bb a {
  text-decoration: none;
  color: #021b48;
}
.translaw .m-card .bb:last-child {
  border-bottom: none;
}

.dog {
  position: relative;
  width: 19%;
  top: -137px;
  z-index: 2;
}

@media (max-width: 576px) {
  .dog {
    top: unset;
    margin: 6%;
    width: 37%;
  }
}
