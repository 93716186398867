.review {
  align-items: flex-start;
  margin: 30px 0;
}

@media (max-width: 576px) {
  .review {
    margin: 10px 0;
  }
}

.review .info {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}



.review .info div img {
  width: 90%;
  margin-left: 5%;
  object-fit: cover;
  cursor: zoom-in;
}

@media (max-width: 576px) {
  .review .info div img {
    width: 90%;
    margin-left: 0%;
    margin-right: 5%;
    object-fit: cover;
    cursor: zoom-in;
  }
}

.review .info div p {
  /* margin-left: 5%; */
  margin-bottom: 0 !important;
  font-family: var(--AppFont);
  font-weight: 500;
}

.reviewDetails .rating {
  display: flex;
  align-items: center;
  margin-left: 0 !important;
}

@media (max-width: 576px) {
  .reviewDetails {
    margin-top: 5%;
  }
}

.reviewDetails .rating p {
  color: var(--cmn-text-color-ash);
  font-weight: 500;
  margin-left: 2%;
  font-family: var(--AppFont);
  margin-bottom: 0 !important;
}

@media (max-width: 576px) {
  .reviewDetails .rating p {
    font-size: 0.9rem;
  }

  .reviewDetails .rating span {
    font-size: 1.2rem;
  }
}

.reviewTextDetails {
  display: block;
  margin-top: 5px;
}

.reviewTextDetails .reviewText {
  font-family: var(--AppFont);
  color: var(--cmn-text-color-ash);
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.8rem;
}


.reviewTextDetails .reviewDate {
  font-family: var(--AppFont);
  color: var(--cmn-text-color-ash);
  font-weight: 500;
  font-size: 0.8rem;
}

@media (max-width: 576px) {
  .reviewTextDetails .reviewText {
    font-size: 0.9rem;
    line-height: 1.6rem;
  }


  .reviewTextDetails .reviewDate {
    font-size: 0.7rem;
  }
}

.reviewTextDetails .seeMoreReplyButton {
  background-color: var(--cmn-gray-light);
  outline: none;
  border: none;
  color: var(--cmn-text-color-ash);
  font-family: var(--AppFont);
  border-radius: 0 !important;
  padding: 5px 10px !important;
}

@media (max-width: 576px) {
  .reviewTextDetails .seeMoreReplyButton {
    font-size: 0.8rem;
    padding: 4px 20px !important;
    margin-bottom: 25px;
  }
}

.moreLink {
  font-family: var(--AppFont);
  text-decoration: none;
  color: #003333;
  text-align: end;
  margin-left: auto;
  font-size: 0.9rem;
  font-weight: 500;
}

@media (max-width: 576px) {
  .moreLink {
    font-size: 0.8rem;
  }
}

.listItem {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 576px) {
  .listItem {
    margin-bottom: 20px;
  }
}

.ReviewReplyText {
  padding-left: 25px;
  border-left: 2px solid var(--cmn-text-color-ash);
}

.modalCloseButton {
  outline: none;
  border: none;
  background-color: var(--cmn-text-color-ash);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -3%;
  right: -3%;
}

.modalCloseButton span {
  color: white;
  font-size: 1.3rem;
}