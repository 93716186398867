.contactUs {
  padding-top: 1.81vw;
  position: relative;
  /* z-index: 2; */
}
@media (max-width: 576px) {
  .contactUs {
    padding-top: calc(60px );
  }
}
.contactUs .contact-card {
  background-color: #fff;
  padding: 5vw 5vw;
  border-radius: 3vw;
}
@media (max-width: 576px) {
  .contactUs .contact-card {
    padding: 10vw 5vw;
  }
}
.contactUs .contact-card .title {
  font-family: "Zen Kaku Gothic New", sans-serif;
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}
@media (min-width: 1801px) {
  .contactUs .contact-card .title {
    font-size: 2.4em;
  }
}
@media (max-width: 576px) {
  .contactUs .contact-card .title {
    font-size: 1.6em;
  }
}
.contactUs .contact-card .subtitle {
  margin-top: 3.5%;
  font-family: "Noto Sans JP";
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
}
@media (min-width: 1801px) {
  .contactUs .contact-card .subtitle {
    font-size: 1.3em;
  }
}
@media (max-width: 576px) {
  .contactUs .contact-card .subtitle {
    font-size: 0.9rem;
  }
}
.contactUs .contact-card .form {
  margin-top: 10%;
}
.contactUs .contact-card .form label {
  font-family: "Noto Sans JP";
  font-size: 1.2rem;
  font-weight: 500;
  align-self: center;
}
/* @media (min-width: 1801px) {
  .contactUs .contact-card .form label {
    font-size: 1.4em;
  }
} */
@media (max-width: 576px) {
  .contactUs .contact-card .form label {
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
}
.contactUs .contact-card .form label .req {
  color: #fff;
  background-color: #ff0000;
  margin-right: 10px;
  padding: 0.2vw 0.5vw;
}
@media (max-width: 576px) {
  .contactUs .contact-card .form label .req {
    margin-right: 5px;
  }
}
.contactUs .contact-card .form .textinpput {
  background-color: #f8f6f4;
  border-radius: 0;
  padding: 1.3vw 1vw;
  width: 100%;
  border: 0 solid;
  color: #787878;
  font-size: 1.2rem;
}

.contactUs .contact-card .form .textinpput.invalid,  .contactUs .contact-card .form .textareainput.invalid{
  
  border: 1px solid #dc3545;
}


/* @media (min-width: 1801px) {
  .contactUs .contact-card .form .textinpput {
    font-size: 1.4em;
  }
} */
@media (max-width: 576px) {
  .contactUs .contact-card .form .textinpput {
    font-size: 0.8rem;
    padding: 1rem 0.8rem;
  }
}
.contactUs .contact-card .form .textinpput:focus {
  outline: none;
}
.contactUs .contact-card .form .textinpput::placeholder {
  color: #c2b49e;
}
.contactUs .contact-card .form .textareainput {
  width: 100%;
  background-color: #f8f6f4;
  border: 0 solid;
  height: 140px;
  resize: none;
  color: #787878;
  font-size: 1.2rem;
  padding: 1.3vw 1vw;
}
/* @media (min-width: 1801px) {
  .contactUs .contact-card .form .textareainput {
    font-size: 1.4em;
  }
} */
@media (max-width: 576px) {
  .contactUs .contact-card .form .textareainput {
    font-size: 0.7em;
    height: 100px;
  }
}
.contactUs .contact-card .form .textareainput::placeholder {
  color: #c2b49e;
}
.contactUs .contact-card .form .textarealabel {
  align-self: baseline;
  padding-top: 1vw;
}
.contactUs .contact-card .form .alertData {
  margin-top: 10%;
  padding: 5vw 3vw;
  width: 100%;
  border: 0.2vw solid #021b48;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 576px) {
  .contactUs .contact-card .form .alertData {
    width: 92%;
    margin-left: 4%;
  }
}
.contactUs .contact-card .form .alertData p {
  font-family: "Noto Sans JP";
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
}
/* @media (min-width: 1801px) {
  .contactUs .contact-card .form .alertData p {
    font-size: 1.4em;
  }
} */
@media (max-width: 576px) {
  .contactUs .contact-card .form .alertData p {
    font-size: 0.85em;
  }
}
.contactUs .contact-card .form .alertData p a {
  color: red;
}
.contactUs .contact-card .form .alertData .checkBoxContainer {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  font-size: 1.4rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 576px) {
  .contactUs .contact-card .form .alertData .checkBoxContainer {
    font-size: 0.9em;
    margin-left: 5%;
  }
}
.contactUs .contact-card .form .alertData .checkBoxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.contactUs
  .contact-card
  .form
  .alertData
  .checkBoxContainer
  input:checked
  ~ .checkmark {
  background-color: #fff;
}
.contactUs .contact-card .form .alertData .checkBoxContainer .checkmark {
  position: absolute;
  top: 0%;
  left: -3%;
  height: 35px;
  width: 35px;
  background-color: #fff;
  border: 1px solid #021b48;
}
@media (min-width: 1801px) {
  .contactUs .contact-card .form .alertData .checkBoxContainer .checkmark {
    top: 15%;
  }
}
@media (max-width: 576px) {
  .contactUs .contact-card .form .alertData .checkBoxContainer .checkmark {
    left: 0%;
    height: 27px;
    width: 27px;
    top: 18%;
  }
}
.contactUs .contact-card .form .alertData .checkBoxContainer .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.contactUs
  .contact-card
  .form
  .alertData
  .checkBoxContainer
  input:checked
  ~ .checkmark:after {
  display: block;
}
.contactUs .contact-card .form .alertData .checkBoxContainer .checkmark:after {
  left: 14px;
  top: -13px;
  width: 20px;
  height: 40px;
  border: solid #021b48;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media (max-width: 576px) {
  .contactUs
    .contact-card
    .form
    .alertData
    .checkBoxContainer
    .checkmark:after {
    width: 20px;
    height: 40px;
    left: 8px;
    top: -16px;
  }
}
.contactUs
  .contact-card
  .form
  .alertData
  .checkBoxContainer:hover
  input
  ~ .checkmark {
  background-color: #fff;
}
.contactUs .contact-card .form .gbtn {
  margin-top: 5%;
  background-color: #101c46;
  text-decoration: none;
  color: #fff;
  font-weight: bol;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 1.7rem;
  padding: 0.5rem 2.8rem;
  border-radius: 0.4rem;
  width: min-content;
  white-space: nowrap;
}
@media (max-width: 576px) {
  .contactUs .contact-card .form .gbtn {
    font-size: 0.9em;
    padding: 0.9vw 6.8vw;
    border-radius: 0.3em;
  }
}
.contactUs .contact-card .form .gbtn:hover {
  background-color: #5f7fbd;
}

.dog {
  width: 32%;
  position: relative;
  top: -5rem;
}
.valueDivD{
  margin-bottom: 3rem;

}
.valueDiv{
  display: flex;
}
.valueDiv p{
  color: #787878;
  font-size: 1.2rem;
  align-self: center;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .dog {
    width: 58%;
    position: relative;
    top: 3rem;
    left: -3rem;
  }
  .valueDiv p{
    margin-left: 1rem;
  }
}
.con {
  position: relative;
  z-index: 3;
}

.backbtn{
  width: 11rem;
  margin: 1rem 0.5rem;
  border: 2px solid #021B48;
  text-align: center;
  background-color: white;
  text-decoration: none;
  border-radius: 7px;
  color: #021B48;
  font-weight: 600;
  padding: 0.5rem 0rem;
  font-size: 1.2rem;
  transition: .3s ease;
}
.backbtn:hover{
  background-color: #021B48;
  color: #fff;
}


.backbtn.disabled{
  width: 11rem;
  margin: 1rem 0.5rem;
  border: 2px solid #657DA0;
  text-align: center;
  background-color: white;
  text-decoration: none;
  border-radius: 7px;
  color: #657DA0;
  font-weight: 600;
  padding: 0.5rem 0rem;
  font-size: 1.2rem;
  transition: .3s ease;
  cursor: no-drop;
}
.backbtn.disabled:hover{
  border: 2px solid #657DA0;
  background-color: white;
  color: #657DA0;
}

.sendbtn{
  width: 11rem;
  margin: 1rem 0.5rem;
  border: 2px solid #FF7417;
  text-align: center;
  background-color: white;
  text-decoration: none;
  border-radius: 7px;
  color: #FF7417;
  font-weight: 600;
  padding: 0.5rem 0rem;
  font-size: 1.2rem;
  transition: .3s ease;

  
}

.sendbtn:hover{
  background-color: #FF7417;
  color: #fff;
}


@media (max-width: 576px) {
  .backbtn{
    width: 8rem;
    font-size: 1.1rem;
    padding: 0.3rem 0;
  }
  .sendbtn{
    width: 8rem;
    font-size: 1.1rem;
    padding: 0.3rem 0;

  }
}