 /* {
  padding-top: 15.81rem;
  padding-bottom: 5rem;
  color: #021b48; 
}*/
/* @media (max-width: 576px) {
   {
    padding-top: calc(100px + 0rem);
  }
} */
.title {
  text-align: center;
}
.title p {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2rem;
  font-size: 3rem;
  margin-bottom: -1.2rem;
}
@media (max-width: 576px) {
  .title p {
    font-size: 1.6em;
    margin-bottom: -8px;
  }
}
.title span {
  font-size: 1.6rem;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: bold;
  letter-spacing: 0;
}
@media (max-width: 576px) {
  .title span {
    font-size: 0.8em;
  }
}
.m-card {
  padding: 0px 5rem 8rem 5rem;
  /* margin-top: 5%; */
}
.m-card .newsType {
  color: #fff;
  background-color: #5f7fbd;
  font-family: "Noto Sans JP";
  font-size: 1.2rem;
  font-weight: bold;
  width: min-content;
  white-space: nowrap;
  padding: .6rem 1.2rem;
  margin-left: auto;
}


.m-card .newsType2 {
  color: #021B48;
  background-color: #FDE169;
  font-family: "Noto Sans JP";
  font-size: 1.2rem;
  font-weight: bold;
  width: min-content;
  white-space: nowrap;
  padding: .6rem 1.2rem;
  margin-left: auto;
}


@media (max-width: 576px) {
  .m-card .newsType {
    font-size: 0.8em;
    padding: 1.5rem 2rem;
  }
  .m-card .newsType2 {
    font-size: 0.8em;
    padding: 1.5rem 2rem;
  }
}
.m-card .date {
  font-family: "Noto Sans JP";
  font-size: 1rem;
  font-weight: 500;
  margin-top: 5%;
  color: #021b48;
}
@media (max-width: 576px) {
  .m-card .date {
    font-size: 0.8em;
  }
}
.m-card .title {
  text-align: start;
  font-family: "Noto Sans JP";
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 6%;
  color: #121e47;
}
@media (max-width: 576px) {
  .m-card .title {
    font-size: 1em;
    text-align: center;
  }
}
.m-card .details {
  color: #121e47;
  font-family: "Noto Sans JP";
  font-size: 1.1rem;
  font-weight: 400;
  margin-top: 8%;
}
@media (max-width: 576px) {
  .m-card .details {
    font-size: 0.85em;
  }
}
.gbtn {
  margin-top: 5%;
  background-color: #101c46;
  text-decoration: none;
  color: #fff;
  font-weight: bol;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 1.7rem;
  padding: 0.5rem 2.8rem;
  border-radius: 0.4rem;
  width: min-content;
  white-space: nowrap;
}
@media (max-width: 576px) {
  .gbtn {
    margin-top: 15%;
    font-size: 0.9em;
    padding: 0.9rem 6.8rem;
    border-radius: 0.3em;
  }
}
.gbtn:hover {
  background-color: #5f7fbd;
}
