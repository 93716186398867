.bgYellow {
  background-color: #fee57d;
}

.bgCardgreen {
  background-color: #a1d7c6;
}
.bgCardgreen p {
  color: #8fccb8;
  font-size: 5vw;
  letter-spacing: calc(0.1em + 0vw);
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  font-weight: 500;
  left: 1.5%;
  bottom: -6%;
  width: 97%;
  z-index: 0;
  margin: 0;
}
@media (min-width: 1801px) {
  .bgCardgreen p {
    font-size: 4.5em;
  }
}
@media (max-width: 576px) {
  .bgCardgreen p {
    bottom: -5%;
    font-weight: 700;
    font-size: 10vw;
  }
}

.bgCardpink {
  background-color: #f495b3;
}
.bgCardpink p {
  color: #f486ad;
  font-size: 5vw;
  letter-spacing: calc(0.1em + 0vw);
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  font-weight: 500;
  left: 1.5%;
  width: 97%;
  z-index: 0;
  bottom: -6%;
  margin: 0;
}
@media (min-width: 1801px) {
  .bgCardpink p {
    font-size: 4.5em;
  }
}
@media (max-width: 576px) {
  .bgCardpink p.bgT {
    bottom: -2%;
    font-weight: 600;
    font-size: 10vw;
    line-height: 11vw;
  }
}

.bgCardorange {
  background-color: #f9a76f;
}
.bgCardorange p {
  color: #f9994d;
  font-size: 5vw;
  letter-spacing: calc(0.1em + 0vw);
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  font-weight: 500;
  left: 1.5%;
  width: 97%;
  z-index: 0;
  bottom: -6%;
  margin: 0;
}
@media (min-width: 1801px) {
  .bgCardorange p {
    font-size: 4.5em;
  }
}
@media (max-width: 576px) {
  .bgCardorange p {
    bottom: -6%;
    font-weight: 600;
    font-size: 10vw;
  }
}

.main {
  margin-top: calc(0px - 11.81vw);
  background-color: #f0ebe5;
  position: relative;
}
@media (max-width: 576px) {
  .main {
    margin-top: -100px;
  }
}

.home .top {
  /* background-image: url(./img/TOP.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw; */
  height: 47.76vw;
}
@media (max-width: 576px) {
  .home .top {
    height: 110vw;
    /* background-image: url(./img/TOP-m.png); */
  }
}
.home .top .yTxtCon {
  padding-top: 19vw;
  padding-left: 52.5vw;
  position: relative;
  display: flex;
  flex-direction: column;
}
.home .top .yTxtCon .yellowTDiv {
  background-color: #fde169;
  width: fit-content;
  margin-top: calc(3px + 0.3vw);
  margin-right: 3px;
  padding: 10px 0 5px calc(15px + 0.2vw);
}
.home .top .yTxtCon .yellowT {
  /* background-color: #fde169; */
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 600;
  white-space: nowrap;
  width: 24vw;

  text-align: center;
  font-size: 1.7vw;
  letter-spacing: 0.35vw;

  margin: 0;
  writing-mode: horizontal-tb;
  text-align: start;
}
@media (max-width: 576px) {
  .home .top .yTxtCon .yellowT {
    writing-mode: vertical-rl;
    width: min-content;
    font-size: 1rem;
    /* padding: 1px;
    margin: 4px; */
    height: fit-content;
    letter-spacing: 0.85vw;
    text-align: end;
    padding-top: 10px;
    margin-bottom: -10px;
  }

  .home .top .yTxtCon .yellowTDiv {
    padding: 0px;
    width: 1.5rem;
  }
}
@media (max-width: 576px) {
  .home .top .yTxtCon {
    padding-left: 0%;
    flex-direction: row-reverse;
    padding-right: 14%;
    padding-top: 31%;
  }
}
.home .top .yTxtCon .mark {
  border: 0.2vw solid #5470a5;
  border-radius: 50%;
  font-family: "Zen Kaku Gothic New", sans-serif;
  color: #5470a5;
  font-size: 1vw;
  font-weight: 600;
  width: 9vw;
  height: 9vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 8%;
  left: 75.2%;
  writing-mode: horizontal-tb;
  letter-spacing: 0.05vw;
}
@media (max-width: 576px) {
  .home .top .yTxtCon .mark {
    writing-mode: vertical-rl;
    font-size: 0.7em;
    width: min-content;
    height: fit-content;
    border-radius: 0%;
    border: none;
    background-color: #fff;
    left: 88.2%;
    top: 28%;
    letter-spacing: 1px;
  }
}
.home .top .yTxtCon .mark p {
  margin: 0;
}
.home .details {
  background-color: #fee57d;
  width: 100%;
  clip-path: ellipse(120% 100% at 50% 100%);
  margin-top: -9vw;
  padding-top: calc(50px + 5vw);
  padding-bottom: calc(200px + 13vw);
}
@media (min-width: 1841px) {
  .home .details {
    clip-path: ellipse(150% 100% at 50% 100%);
  }
}
@media (max-width: 576px) {
  .home .details {
    /* margin-top: 7vw; */
    clip-path: ellipse(180% 100% at 50% 100%);
    padding-bottom: calc(0px + 20vw);
  }
}
.home .details .infoTxt {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 2.5vw;
  line-height: 3.4vw;
  letter-spacing: calc(0.05em + 0.05vw);
  font-weight: bold;
}
@media (min-width: 1841px) {
  .home .details .infoTxt {
    font-size: 3.2em;
  }
}
@media (max-width: 576px) {
  .home .details .infoTxt {
    font-size: 1em;
    line-height: 1.6em;
    letter-spacing: calc(0.03em + 0.05vw);
  }
}
.home .details .info_details {
  font-size: 1.3vw;
  font-family: "Zen Kaku Gothic New", sans-serif;
  line-height: 2.4vw;
  text-align: start;
  font-weight: 500;
}
@media (min-width: 1841px) {
  .home .details .info_details {
    font-size: 1.5em;
  }
}
@media (max-width: 576px) {
  .home .details .info_details {
    font-size: 0.9em;
    line-height: 1.75em;
    text-align: center;
  }
}
.home .details .exbtn {
  background-color: #fff;
  text-decoration: none;
  font-family: "Zen Kaku Gothic New", sans-serif;
  padding: 0.4vw 0.8vw;
  border-radius: calc(5px + 0.5vw);
  font-size: 1.5vw;
  font-weight: bold;
  color: #101c46;
  border: 2px solid #101c46;
}
@media (min-width: 1841px) {
  .home .details .exbtn {
    font-size: 1.5em;
  }
}
@media (max-width: 576px) {
  .home .details .exbtn {
    font-size: 1em;
    border-radius: calc(10px + 0.8vw);
    padding: 0.8vw 2.8vw;
    font-weight: 500;
  }
}
.home .details .exbtn:hover {
  background-color: #6080bb;
  color: #fff;
}
.home .details .illust {
  position: absolute;
  top: 32%;
  width: 140%;
  left: -30%;
}
@media (max-width: 576px) {
  .home .details .illust {
    position: relative;
    width: 100%;
    left: 0%;
    top: 6%;
  }
}
.home .manual {
  background-color: #fee57d;
  width: 100%;
  background-image: url(./img/b1.png);
  background-repeat: no-repeat;
  margin-top: -7vw;
  padding-top: calc(30px + 3vw);
  /* padding-bottom: calc(20px + 5vw); */
  background-position: top;
  /* z-index: 99; */
  position: relative;
  background-size: auto;
}
@media (min-width: 1801px) {
  .home .manual {
    margin-top: -10vw;
    background-image: url(./img/b1-1.png);
    background-size: 135%;
  }
}
@media (max-width: 576px) {
  .home .manual {
    background-size: 100% 100%;
  }
}
.home .manual .cardholder {
  padding: 7% 6%;
  padding-top: 10%;
  background-color: #fff;
  border: calc(1px + 0.15vw) solid #021b48;
  border-top-right-radius: 3vw;
  border-bottom-left-radius: 3vw;
  margin-top: -10vw;
  z-index: 999;
}
@media (max-width: 576px) {
  .home .manual .cardholder {
    margin-top: -15vw;
    border: calc(2px + 0.15vw) solid #021b48;
    border-top-right-radius: 8vw;
    border-bottom-left-radius: 8vw;
  }
}
.home .manual .cardholder .detailsM {
  position: relative;
}
.home .manual .cardholder .detailsM p:nth-child(1) {
  font-size: 4vw;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.5vw;
  margin-bottom: 0 !important;
}
@media (min-width: 1801px) {
  .home .manual .cardholder .detailsM p:nth-child(1) {
    font-size: 4em;
  }
}
@media (max-width: 576px) {
  .home .manual .cardholder .detailsM p:nth-child(1) {
    font-size: 1.9em;
    font-weight: 500;
  }
}
.home .manual .cardholder .detailsM p:nth-child(2) {
  font-size: 1.5vw;
  font-family: "Noto Sans JP";
  font-weight: bold;
  letter-spacing: 0;
}
@media (min-width: 1801px) {
  .home .manual .cardholder .detailsM p:nth-child(2) {
    font-size: 2em;
  }
}
@media (max-width: 576px) {
  .home .manual .cardholder .detailsM p:nth-child(2) {
    font-size: 0.8em;
    font-weight: 500;
    margin-top: -10px;
    margin-bottom: 5px !important;
  }
}
.home .manual .cardholder .detailsM img {
  position: absolute;
  top: -22%;
  left: -4%;
  width: 27%;
}
@media (min-width: 1801px) {
  .home .manual .cardholder .detailsM img {
    left: 0%;
    top: -30%;
  }
}
@media (max-width: 576px) {
  .home .manual .cardholder .detailsM img {
    top: -37%;
    left: 16%;
    width: 19%;
  }
}
.home .manual .cardholder .mtx {
  position: relative;
  margin: 3% 0;
}
.home .manual .cardholder .mtx p {
  margin: 0;
  font-family: "Noto Sans JP";
  font-weight: bold;
  font-size: 1.45vw;
  padding: 1%;
  z-index: 1;
}
@media (min-width: 1801px) {
  .home .manual .cardholder .mtx p {
    font-size: 1.8em;
  }
}
@media (max-width: 576px) {
  .home .manual .cardholder .mtx p {
    font-size: 0.75em;
  }
}
.home .manual .cardholder .mtx img {
  position: absolute;
  top: -10.5vw;
  right: 0%;
  height: 11vw;
  width: auto;
  z-index: 0;
}
@media (min-width: 1801px) {
  .home .manual .cardholder .mtx img {
    right: 0%;
  }
}
.home .manual .cardholder .detailTxt {
  font-family: "Noto Sans JP";
  font-weight: 500;
  font-size: 1.5vw;
  line-height: 2.5vw;
}
@media (min-width: 1801px) {
  .home .manual .cardholder .detailTxt {
    font-size: 1.8em;
  }
}
@media (max-width: 576px) {
  .home .manual .cardholder .detailTxt {
    font-size: 0.78em;
    line-height: 1.6em;
    font-weight: 400;
    margin-top: 5px;
  }
}
.home .manual .cardholder .items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.home .manual .cardholder .items .item {
  margin: calc(7px + 0.7vw);
  background-color: #5e7fbc;
  width: 13vw;
  height: 13vw;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5vw 1vw;
}
@media (min-width: 1801px) {
  .home .manual .cardholder .items .item {
    width: 13rem;
    height: 13rem;
    padding: 1.5rem 1rem;
  }
}
@media (max-width: 576px) {
  .home .manual .cardholder .items .item {
    width: calc(90px + 5vw);
    height: calc(90px + 5vw);
  }
}
.home .manual .cardholder .items .item p {
  margin: 0;
  color: #fff;
  text-align: center;
  font-size: 1.2vw;
  margin-bottom: 0.6vw;
  font-weight: bold;
  font: "Noto Sans JP";
}
@media (min-width: 1801px) {
  .home .manual .cardholder .items .item p {
    font-size: 1rem;
  }
}
@media (max-width: 576px) {
  .home .manual .cardholder .items .item p {
    font-size: 0.7rem;
    font-weight: 500;
    margin-bottom: 0.4rem;
  }
}
.home .manual .cardholder .items .item img {
  height: 4.2rem;
  justify-self: center;
}
@media (max-width: 576px) {
  .home .manual .cardholder .items .item img {
    height: 40px;
  }
}
.home .manual .t1 {
  font-weight: 500;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 1.8vw;
  line-height: 3.6vw;
  margin-top: 6vw;
}
@media (min-width: 1801px) {
  .home .manual .t1 {
    font-size: 2em;
  }
}
@media (max-width: 576px) {
  .home .manual .t1 {
    font-size: 1.05em;
    line-height: 2em;
  }
}

/* .bgparallaxDiv1{
  background-image: url(./img/a1.png);
  background-size: 100% 100%;
    background-repeat: no-repeat;
    width: fit-content;
    padding: 0;
    position: relative;
    left: -19%;

    height: 70vw;
  z-index: 999;
  right: -8%;
}

.home .maskContainer {
  height: auto;
  display: flex;
  align-items: end;
  margin-top: -20vw;
  position: relative;
}
.home .maskContainer .mask {
  height: 70vw;
  z-index: 999;
  right: -8%;
  position: relative;
  top: 0vw;
  background-image: url(./img/mask11.jpg);
  background-size: auto 110%;
  mix-blend-mode: multiply;
  will-change: filter;
  background-repeat: no-repeat;
} */
.bgparallaxDiv {
  margin-top: -10vw;
  margin-bottom: -8vw;
  overflow: hidden;
  height: 70vw;
}

.bgparallaxDiv1 {
  background-image: url(./img/a1.png);
  background-size: 100% 101%;
  background-repeat: no-repeat;
  width: fit-content;
  padding: 0;
  position: relative;
  /* right: -8%; */
  margin-left: auto;
}
.home .maskContainer .mask {
  height: 70vw;
  position: relative;
  background-image: url(./img/mask11.jpg);
  background-size: auto 101%;
  mix-blend-mode: multiply;
  will-change: filter;
  background-repeat: no-repeat;
  background-position: 101.76% 50%;
  width: fit-content;
  padding: 0;
}

@media (min-width: 1801px) {
  .bgparallaxDiv {
    height: 53vw;
  }
  .home .maskContainer .mask {
    height: 51vw;
  }
}

.home .maskContainer .mask img {
  height: 101%;
}
@media (max-width: 576px) {
  .home .maskContainer .mask img {
    right: 2%;
  }
}
.home .service {
  background-color: #fee57d;
  width: 100%;
  clip-path: ellipse(200% 100% at 50% 100%);
  margin-top: -2vw;
  padding-top: 16vw;
  padding-bottom: 20vw;
  position: relative;
  z-index: 99;
}
@media (min-width: 1801px) {
  .home .service {
    padding-bottom: 10vw;
  }
}
@media (max-width: 576px) {
  .home .service {
    /* margin-top: 76vw; */
    clip-path: ellipse(250% 100% at 50% 100%);
    padding-top: 13vw;
  }
}
.home .service .title {
  text-align: center;
}
.home .service .title p {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.8vw;
  font-size: 3.4vw;
  margin-bottom: -1.2vw;
}
@media (max-width: 576px) {
  .home .service .title p {
    font-size: 1.7em;
  }
}
.home .service .title span {
  font-size: 2.7vw;
  font-family: "Noto Sans JP";
  font-weight: bold;
  letter-spacing: 0;
}
@media (max-width: 576px) {
  .home .service .title span {
    font-size: 0.8em;
  }
}
.home .service .serviceInfo {
  background-color: #fff;
  padding: calc(10px + 2vw) calc(8px + 0.8vw);
  margin-top: calc(70px + 7vw);
  border-top-left-radius: calc(20px + 2vw);
  position: relative;
  width: 55%;
  margin-right: 25%;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .home .service .serviceInfo {
    margin-top: calc(30px + 3vw);
    width: 85%;
    margin-right: 0%;
    padding: calc(10px + 1.5vw) calc(1px + 0.4vw);
    margin-bottom: 25vw;
  }
}
.home .service .serviceInfo li {
  list-style: none;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 400;
  font-size: 1.8vw;
  margin-top: 1.1vw;
  font-weight: 500;
}
@media (min-width: 1801px) {
  .home .service .serviceInfo li {
    font-size: 1.9em;
  }
}
@media (max-width: 576px) {
  .home .service .serviceInfo li {
    font-size: 0.85em;
  }
}
.home .service .serviceInfo li::before {
  content: "";
  display: inline-block;
  height: 3vw;
  width: 3vw;
  margin-right: 10px;
  background-image: url(./img/ticmark.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  top: 0.5vw;
}
@media (max-width: 576px) {
  .home .service .serviceInfo li::before {
    height: 3.8vw;
    width: 3.8vw;
  }
}
.home .service .serviceInfo .imgd {
  position: absolute;
  top: -10vw;
  height: 12vw;
}
@media (max-width: 576px) {
  .home .service .serviceInfo .imgd {
    top: -16vw;
    height: 20vw;
  }
}
.home .service .serviceInfo .imgav {
  position: absolute;
  bottom: -3vw;
  height: 27vw;
  left: 83%;
}
@media (max-width: 576px) {
  .home .service .serviceInfo .imgav {
    bottom: -22vw;
    height: 44vw;
    left: 49%;
  }
}
.home .service .cardT {
  padding: 2.5vw 2.8vw;
  padding-bottom: 0;
  margin-top: 3vw;
  font-family: "Montserrat", sans-serif;
  /* text-transform: uppercase; */
  position: relative;
  height: 29vw;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: calc(1em + 1vw);
  overflow: hidden;
}
@media (min-width: 1801px) {
  .home .service .cardT {
    height: 24vw;
  }
}
@media (max-width: 576px) {
  .home .service .cardT {
    height: 67vw;
    padding: 4.5vw 2.8vw;
  }
}
.home .service .cardT .df {
  height: 28vw;
  position: absolute;
  bottom: 0;
  left: 12%;
}
@media (min-width: 1801px) {
  .home .service .cardT .df {
    height: 18vw;
  }
}
@media (max-width: 576px) {
  .home .service .cardT .df {
    height: 26vw;
    left: 63%;
    z-index: 4;
  }

  .home .service .cardT.bgCardgreen .df {
    height: 39vw;
    left: 60%;
    bottom: -7%;
  }

  .home .service .cardT.bgCardpink .df {
    height: 39vw;
    left: 58%;
    bottom: -7%;
  }

  .home .service .cardT.bgCardorange .df {
    height: 34vw;
    left: 63%;
  }
}
.home .service .cardT .art {
  height: 4vw;
}
@media (min-width: 1801px) {
  .home .service .cardT .art {
    height: 3vw;
  }
}
@media (max-width: 576px) {
  .home .service .cardT .art {
    height: 1.1rem;
  }
}
.home .service .cardT .link1,
.home .service .cardT .link2,
.home .service .cardT .link3 {
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  margin-bottom: 0;
  font-family: "Zen Kaku Gothic New", sans-serif;
  position: relative;
  z-index: 1;
  text-decoration: none;
  color: #101c46;
  font-weight: 500;
  position: absolute;
  bottom: 5%;
}

.home .service .cardT .link1:hover {
  color: #139197;
}
.home .service .cardT .link2:hover {
  color: #eb008b;
}
.home .service .cardT .link3:hover {
  color: #ee4035;
}

.home .service .cardT .link1:hover::before {
  color: #139197;
}
.home .service .cardT .link2:hover::before {
  color: #eb008b;
}
.home .service .cardT .link3:hover::before {
  color: #ee4035;
}
@media (min-width: 1801px) {
  /* .home .service .cardT .link1,
  .home .service .cardT .link2,
  .home .service .cardT .link3 {
    top: 19vw;
  } */
}
@media (max-width: 576px) {
  .home .service .cardT .link1,
  .home .service .cardT .link2,
  .home .service .cardT .link3 {
    font-size: 0.8em;
    top: 75%;
    left: 2%;
    font-weight: 600;
  }
}
.home .service .cardT .link3::before,
.home .service .cardT .link2::before,
.home .service .cardT .link1::before {
  background-position: center;
  border-radius: 50%;
  content: "→";
  /* height: 35px; */
  opacity: 0.7;
  position: relative;
  /* width: 35px; */
  z-index: -1;
  align-self: center;
  display: inline-block;
  text-align: center;

  right: -0.6rem;
  font-size: 1.7rem;
  padding: 0.2rem 0.55rem;
}
@media (min-width: 1801px) {
  .home .service .cardT .link3::before,
  .home .service .cardT .link2::before,
  .home .service .cardT .link1::before {
  }
}

@media (max-width: 576px) {
  .home .service .cardT .link3::before,
  .home .service .cardT .link2::before,
  .home .service .cardT .link1::before {
    font-size: 1rem;
    padding: 0.2rem 0.35rem;
  }
}
.home .service .cardT .link1::before {
  background-color: #30a681;
}
.home .service .cardT .link2::before {
  background-color: #f359a2;
}
.home .service .cardT .link3::before {
  background-color: #e77d2c;
}
.home .service .cardT .sub {
  color: #101c46;
  position: unset;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 2vw;
  font-weight: 500;
  letter-spacing: 0.1vw;
  line-height: 3vw;
  margin-top: 0;
  margin-left: 0;
  width: min-content;
}
@media (min-width: 1801px) {
  .home .service .cardT .sub {
    font-size: 1.8em;
    line-height: 2rem;
    text-align: start;
  }
}
@media (max-width: 576px) {
  .home .service .cardT .sub {
    font-size: 1em;
    /* margin-top: 10px; */
    line-height: 1.1em;
    margin-left: 6px;
    font-weight: 600;
  }
}
.home .service .cardT .items {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  margin-top: -3.5vw;
  justify-content: end;
}
@media (max-width: 576px) {
  .home .service .cardT .items {
    justify-content: center;
    margin-top: 0.5vw;
  }
}
.home .service .cardT .items .item {
  margin: 0.9vw;
  background-color: #fff;
  width: 12.5vw;
  height: 12.5vw;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0.6vw;
}
@media (min-width: 1801px) {
  .home .service .cardT .items .item {
    width: 11rem;
    height: 11rem;
    margin: 2rem;
    padding: 0 0.5rem;
  }
}
@media (max-width: 576px) {
  .home .service .cardT .items .item {
    width: 26vw;
    height: 26vw;
  }
}
.home .service .cardT .items .item p {
  margin: 0;
  color: #101c46;
  text-align: center;
  font-size: 1.3vw;
  letter-spacing: 0.1vw;
  margin-bottom: 0;
  font-family: "Noto Sans JP";
  font-weight: 500;
  position: unset;
}
@media (min-width: 1801px) {
  .home .service .cardT .items .item p {
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
  }
}
@media (max-width: 576px) {
  .home .service .cardT .items .item p {
    font-size: 3vw;
    font-weight: 700;
  }
}
.home .service .cardT .items .item img {
  width: 3.8vw;
  justify-self: center;
}
.home .service .cardT .items .pos:nth-child(1) {
  margin-right: -7%;
}
.home .service .cardT .items .pos:nth-child(3) {
  margin-left: -7%;
}
.home .service .cardT .items .pos:nth-child(2) {
  margin-top: 20%;
}
@media (max-width: 576px) {
  .home .service .cardT .items .pos:nth-child(2) {
    margin-top: 15%;
  }
}
.bgparallaxDiv2 {
  background-image: url(./img/a222.png);
  background-size: 101% 101%;
  background-position: center;
  background-repeat: no-repeat;
  width: fit-content;
  padding: 0;
  position: relative;
  left: -22%;
  top: -3vw;
}
@media (max-width: 576px) {
  .bgparallaxDiv2 {
    top: -2.4rem;
  }
}
.home .service .mask2 {
  height: 54vw;
  position: relative;
  background-image: url(./img/mask22.jpg);
  background-size: auto 110%;
  mix-blend-mode: multiply;
  will-change: filter;
  background-repeat: no-repeat;
  background-position: 101.76% 50%;
  width: fit-content;
  padding: 0;
}

.home .service .mask2 img {
  transform: scale(1.01);
}

@media (min-width: 1801px) {
  .home .service .mask2 {
    height: 38vw;
  }
  .home .service .mask2 img {
    height: 43vw;
    top: -3vw;
    z-index: -1;
    left: -13%;
  }
}
.home .service .end {
  margin-top: -13vw;
  font-family: "Zen Kaku Gothic New", sans-serif;
  line-height: 3.5vw;
  font-size: 2vw;
  text-align: end;
  margin-bottom: 5vw;
  font-weight: 500;
}
@media (min-width: 1801px) {
  .home .service .end {
    /* margin-top: 32vw; */
  }
}
@media (max-width: 576px) {
  .home .service .end {
    font-size: 1rem;
    line-height: 2em;
    margin-top: -22vw;
    margin-bottom: 30vw;
  }
}
.home .insurance {
  background-color: #f0ebe5;
  width: 100%;
  clip-path: ellipse(120% 100% at 50% 100%);
  margin-top: -16vw;
  padding-top: calc(50px + 5vw);
  padding-bottom: calc(30px + 3vw);
  position: relative;
  z-index: 99;
}

.home .insurance .mtx {
  position: relative;
}
.home .insurance .mtx p {
  margin: 0;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: bold;
  font-size: 1.45rem;
  padding: 1%;
  z-index: 1;
}

.home .insurance .warning {
  width: 50px;
  margin: 0 auto;
  display: flex;
}

.home .insurance .warningTxt {
  color: #f69656;
}

@media (min-width: 1801px) {
  .home .insurance .mtx p {
    font-size: 1.8em;
  }
}
@media (max-width: 576px) {
  .home .insurance .warning {
    width: 33px;
    margin: 62px auto 0;
  }
  .home .insurance .mtx p {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .home .insurance {
    margin-top: -20vw;
    clip-path: ellipse(180% 100% at 50% 100%);
  }
}
.home .insurance .title {
  text-align: center;
}
.home .insurance .title p {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.8vw;
  font-size: 3.4vw;
  margin-bottom: -1vw;
}
@media (max-width: 576px) {
  .home .insurance .title p {
    font-size: 1.7em;
  }
}
.home .insurance .title span {
  font-size: 2.1vw;
  font-family: "Noto Sans JP";
  font-weight: bold;
  letter-spacing: 0;
}
@media (max-width: 576px) {
  .home .insurance .title span {
    font-size: 0.8em;
  }
}
.home .insurance .policyCard {
  width: 100%;
  margin-top: calc(50px + 5vw);
  background-color: #fff;
  border-radius: calc(15px + 1.5vw);
  position: relative;
  padding-bottom: 6rem;
  font-size: 1.1rem;
}

.home .insurance .policyCard small {
  font-size: 0.8rem;
}
@media (max-width: 576px) {
  .home .insurance .policyCard small {
    font-size: 2.6vw;
  }
  .home .insurance .policyCard {
    font-size: 0.85rem;
    margin-top: 30px;
    padding-bottom: 1.4rem;
  }
}
.home .insurance .policyCard .cat1 {
  position: absolute;
  bottom: 10%;
  left: 5%;
  width: 20%;
}
.home .insurance .policyCard .cat2 {
  position: absolute;
  top: -7vw;
  right: 0%;
  height: 7.5vw;
}
@media (max-width: 576px) {
  .home .insurance .policyCard .cat1 {
    bottom: 33%;
  }

  .home .insurance .policyCard .cat2 {
    height: 12vw;
    top: -10.5vw;
  }
}
.home .insurance .policyCard .title {
  font-size: 1.8vw;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
}
@media (max-width: 576px) {
  .home .insurance .policyCard .title {
    font-size: 0.8em;
  }
}
.home .insurance .policyCard .link {
  font-size: 1.8vw;
  font-family: "Zen Kaku Gothic New", sans-serif;
  color: #021b48;
  text-decoration: none;
}
@media (max-width: 576px) {
  .home .insurance .policyCard .link {
    font-size: 0.6em;
  }
}
.home .news {
  background-color: #f0ebe5;
  margin: 1rem 0;
}
.home .news .title {
  text-align: center;
}
.home .news .title p {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.8vw;
  font-size: 3.4vw;
  margin-bottom: -1vw;
}
@media (max-width: 576px) {
  .home .news .title p {
    font-size: 1.7em;
  }
}
.home .news .title span {
  font-size: 2.1vw;
  font-family: "Noto Sans JP";
  font-weight: bold;
  letter-spacing: 0;
}
@media (max-width: 576px) {
  .home .news .title span {
    font-size: 0.8em;
  }
}
.home .news .item {
  background-color: #fff;
  margin-top: 2.2vw;
  display: flex;
  text-decoration: none !important;
}
.home .news .item .notice,
.home .news .item .media {
  font-family: "Noto Sans JP";
  font-size: 1.3vw;
  width: min-content;
  white-space: nowrap;
  padding: 0.5vw 0.8vw;
  font-weight: 500;
  
}
@media (max-width: 576px) {
  .home .news .item .notice,
  .home .news .item .media {
    font-size: 0.75em;
  }

  .home .news .item {
    margin-bottom: 15px;
  }
}
.home .news .item .notice {
  background-color: #5f7fbd;
  color: #fff;
  text-decoration: none!important;
}
.home .news .item .media {
  background-color: #fde169;
  color: #101c46;
  text-decoration: none!important;
}
.home .news .item .date {
  color: #101c46;
  font-family: "Noto Sans JP";
  font-size: 1.2vw;
}
@media (min-width: 1801px) {
  .home .news .item .date {
    font-size: 1.6em;
  }
}
@media (max-width: 576px) {
  .home .news .item .date {
    font-size: 0.6em;
  }
}
.home .news .item .detailsN {
  color: #101c46;
  font-family: "Noto Sans JP";
  padding: 1vw 0.5vw;
  font-size: 1.65vw;
  padding-right: 18%;
}
@media (min-width: 1801px) {
  .home .news .item .detailsN {
    font-size: 1.8em;
  }
}
@media (max-width: 576px) {
  .home .news .item .detailsN {
    font-size: 0.75em;
  }
}
.home .news .gbtn {
  background-color: #101c46;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 1.7vw;
  padding: 0.7vw 1.3vw;
  border-radius: 0.8vw;
}
@media (max-width: 576px) {
  .home .news .gbtn {
    font-size: 1em;
    padding: 0.7em 1em;
    border-radius: 0.5em;
  }
}
.home .news .gbtn:hover {
  background-color: #5f7fbd;
}
.home .info {
  padding: 6rem 0 20rem;
}
@media (max-width: 576px) {
  .home .info {
    padding: 50px 0;
  }
}
.home .info .items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media (max-width: 576px) {
  .home .info .items {
    margin-top: 3rem;
    margin-bottom: 7rem;
  }
}
.home .info .items .item1,
.home .info .items .item2,
.home .info .items .item3 {
  margin: 1.8vw;
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.8vw;
  cursor: pointer;
}
@media (min-width: 1801px) {
  .home .info .items .item1,
  .home .info .items .item2,
  .home .info .items .item3 {
    margin: 0.8vw;
    width: 13vw;
    height: 13vw;
  }
}

@media (max-width: 576px) {
  .home .info .items .item1,
  .home .info .items .item2,
  .home .info .items .item3 {
    font-size: 0.9em;
    width: 210px;
    height: 80px;
    border-radius: 20px;
    flex-direction: row;
  }
}
.home .info .items .item1 p,
.home .info .items .item2 p,
.home .info .items .item3 p {
  margin: 0;
  color: #101c46;
  text-align: center;
  font-size: 2.2vw;
  font-weight: bold;
  margin-left: 0;
}
@media (max-width: 576px) {
  .home .info .items .item1 p,
  .home .info .items .item2 p,
  .home .info .items .item3 p {
    font-size: 1.2em;
    letter-spacing: 0.2em;
    margin-left: 10px;
    text-align: left;
  }
}
.home .info .items .item1 img,
.home .info .items .item2 img,
.home .info .items .item3 img {
  height: calc(25px + 2.5vw);
  justify-self: center;
  margin-bottom: 0.4vw;
}
@media (max-width: 576px) {
  .home .info .items .item1 img,
  .home .info .items .item2 img,
  .home .info .items .item3 img {
    height: 25px;
  }
}
.home .info .items .item1 {
  background-color: #a1d7c6;
}
.home .info .items .item1:hover {
  background-color: #a0e5d2;
  border: calc(2px + 0.2vw) solid #63dbba;
  transition: all 0.6s ease;
}
.home .info .items .item2 {
  background-color: #f394b2;
}
.home .info .items .item2:hover {
  border: calc(2px + 0.2vw) solid #ff8fb3;
  background-color: #ffb6cf;
  transition: all 0.6s ease;
}
.home .info .items .item3 {
  background-color: #f69656;
  /* margin-bottom: 23rem; */
}
.home .info .items .item3:hover {
  border: calc(2px + 0.2vw) solid #ff914e;
  background-color: #ffbc92;
  transition: all 0.6s ease;
}
.home .info .items .item2 p {
  font-size: calc(0.9em + 0.6vw);
  line-height: 1.7rem;
}
@media (max-width: 576px) {
  .home .info .items .item2 p {
    line-height: 1.2rem;
  }
}
.ls1 {
  letter-spacing: -1px;
}

.ls2 {
  letter-spacing: -2px;
}
.ls4 {
  letter-spacing: -4px;
}
.ls5 {
  letter-spacing: -5px;
}

.svg1 .cls-1 {
  letter-spacing: -0.07em;
}
.svg1 .cls-2 {
  fill: #fff;
}
.svg1 .cls-3 {
  letter-spacing: -0.05em;
}
.svg1 .cls-4 {
  letter-spacing: -0.1em;
}
.svg1 .cls-5 {
  letter-spacing: -0.02em;
}
.svg1 .cls-6 {
  letter-spacing: -0.05em;
}
.svg1 .cls-7 {
  letter-spacing: -0.23em;
}
.svg1 .cls-8 {
  letter-spacing: -0.12em;
}
.svg1 .cls-9 {
  letter-spacing: -0.12em;
}
.svg1 .cls-10 {
  letter-spacing: -0.06em;
}
.svg1 .cls-11 {
  letter-spacing: 0em;
}
.svg1 .cls-12 {
  fill: #101c46;
  font-family: "Noto Sans JP";
  font-weight: bold;
  font-size: 22px;
}
.svg1 .cls-13 {
  letter-spacing: -0.04em;
}
.svg1 .cls-14 {
  letter-spacing: -0.04em;
}
.svg1 .cls-15 {
  letter-spacing: -0.13em;
}
.svg1 .cls-16 {
  letter-spacing: -0.25em;
}
.svg1 .cls-17 {
  letter-spacing: -0.07em;
}
.svg1 .cls-18 {
  letter-spacing: -0.51em;
}
.svg1 .cls-19 {
  letter-spacing: -0.05em;
}
.svg1 .cls-20 {
  letter-spacing: -0.01em;
}
.svg1 .cls-21 {
  letter-spacing: -0.03em;
}

.slides {
  z-index: 0;
  height: inherit;
}

.slides > div {
  height: inherit;
}

.policydiv {
  /* width: 20%; */
  position: relative;
  z-index: 2;
}
.art2 {
  position: relative;
  z-index: 0;
  width: 30%;
  top: -6rem;
}
@media (max-width: 576px) {
  .slides {
    z-index: 0;
    /* transform: scale(1.3); */
  }
  .art2 {
    width: 40%;
    top: -2rem;
    left: -6%;
  }
}
