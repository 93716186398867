.navWizard {
  width: fit-content !important;
  padding: 0;
}
.navWizard > li {
  float: left;
}
.navWizard > li > a {
  position: relative;
  background-color: #eeeeee;
  padding: 8.7px 15px;
  color: #428bca;
  text-decoration: none;
}
.white.navWizard > li > a {
  background-color: white;
}
.navWizard > li > a .badge {
  margin-left: 3px;
  color: #eeeeee;
  background-color: #428bca;
}
.navWizard > li:not(:first-child) > a {
  padding-left: 34px;
}
.navWizard > li:not(:first-child) > a:before {
  width: 0px;
  height: 0px;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 20px solid #ffffff;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
}
.white.navWizard > li:not(:first-child) > a:before {
  border-left: 20px solid #f0ebe5;
}
.navWizard > li:not(:last-child) > a {
  margin-right: 6px;
}
.navWizard > li:not(:last-child) > a:after {
  width: 0px;
  height: 0px;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 20px solid #eeeeee;
  position: absolute;
  content: "";
  top: 0;
  right: -20px;
  z-index: 2;
}
.white.navWizard > li:not(:last-child) > a:after {
  border-left: 20px solid white;
}
.navWizard > li:first-child > a {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.navWizard > li:last-child > a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.navWizard > li.done:hover > a,
.navWizard > li:hover > a {
  background-color: #d5d5d5;
}
.navWizard > li.done:hover > a:before,
.navWizard > li:hover > a:before {
  border-right-color: #d5d5d5;
}
.navWizard > li.done:hover > a:after,
.navWizard > li:hover > a:after {
  border-left-color: #d5d5d5!important;
}
.navWizard > li.done > a {
  background-color: #e2e2e2;
}
.navWizard > li.done > a:before {
  border-right-color: #e2e2e2;
}
.navWizard > li.done > a:after {
  border-left-color: #e2e2e2;
}
.navWizard > li.active > a,
.navWizard > li.active > a:hover,
.navWizard > li.active > a:focus {
  color: #ffffff;
  background-color: #428bca;
}
.navWizard > li.active > a:after {
  border-left-color: #428bca !important;
}
.white.navWizard > li.active > a:after {
  border-left-color: #428bca !important;
}
.navWizard > li.active > a .badge {
  color: #428bca;
  background-color: #ffffff;
}
.navWizard > li.disabled > a {
  color: #777777;
}
.navWizard > li.disabled > a:hover,
.navWizard > li.disabled > a:focus {
  color: #777777;
  text-decoration: none;
  background-color: #eeeeee;
  cursor: default;
}
.navWizard > li.disabled > a:before {
  border-right-color: #eeeeee;
}
.navWizard > li.disabled > a:after {
  border-left-color: #eeeeee;
}
.navWizard.nav-justified > li {
  float: none;
}
.navWizard.nav-justified > li > a {
  padding: 10px 15px;
}
@media (max-width: 768px) {
  .navWizard.nav-justified > li > a {
    border-radius: 4px;
    margin-right: 0;
  }
  .navWizard.nav-justified > li > a:before,
  .navWizard.nav-justified > li > a:after {
    border: none !important;
  }
}
@media screen and (max-width: 576px) {
  .navWizard > li {
    font-size: 3vw;
  }

  .navWizard > li > a {
    padding: 7.5px 0px;
  }

  .navWizard > li:not(:first-child) > a:before {
    border-top: 4vw inset transparent;
    border-bottom: 4vw inset transparent;
  }
  .navWizard > li:not(:last-child) > a {
    margin-right: 5px;
  }
  .navWizard > li:not(:last-child) > a:after {

    border-top: 4vw inset transparent;
    border-bottom: 4vw inset transparent;
  }

  .navWizard > li:not(:first-child) > a {
    padding-left: 25px;
  }

  .navWizard > li:first-child > a {
    padding-left: 10px;
  }
  .navWizard > li:last-child > a {
    padding-right: 10px;
  }
}
