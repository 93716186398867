.footer {
    position: relative;
}
.footer .footerContainer {

    background-color: #becce6;
    position: relative;
    padding: calc(20px + 2vw) 0;
    z-index: 20;
  }
  .footer .logo {
    width: 6.197vw;
    height: 5.958vw;
  }
  @media (max-width: 576px) {
    .footer .logo {
      width: 18vw;
      height: auto;
    }
  }
  .footer p {
    font-family: "Zen Kaku Gothic New", sans-serif;
    font-size: 1vw;
    margin-left: 1.2vw;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 0.3vw;
    color: #021b48;
  }
  @media (min-width: 1801px) {
    .footer p {
      font-size: 1.2em;
    }
  }
  @media (max-width: 576px) {
    .footer p {
      font-size: 4vw;
    font-weight: 600;
    }
  }
  .footer .link1 {
    font-family: "Zen Kaku Gothic New", sans-serif;
    text-decoration: none;
    color: #021b48;
    margin-left: 2.5vw;
    font-size: 1vw;
    font-weight: bold;
    margin-right: 0;
  }
  @media (max-width: 576px) {
    .footer .link1 {
      font-size: 3.4vw;
      font-weight: 600;
      margin-left: 2.5vw;
      margin-right: 2.5vw;
    }
  }
  .footer .link2 {
    font-family: "Zen Kaku Gothic New", sans-serif;
    text-decoration: none;
    color: #021b48;
    margin-left: 2vw;
    margin-top: 0;
    font-size: 1vw;
    position: relative;
  }
  @media (min-width: 2000px) {
    .footer p {
      font-size: 1.1rem;
    }
    .footer .link1 {
      font-size: 1.1rem;

    }
    .footer .link2 {
      font-size: 1rem;

    }
  }
  .footer .link2::after {
    content: "";
    position: absolute;
    bottom: -2px;
    width: 0%;
    border-bottom: 1px solid #021b48;
    left: 0;
    transition: .3s ease-in;

  }

  .footer .link2:hover::after {
    content: "";
    position: absolute;
    bottom: -2px;
    width: 100%;
    border-bottom: 1px solid #021b48;
    left: 0;

  }

  /* .footer .link2:hover {
    border-bottom: 1px solid #021b48;
    transition: all 0.3s ease;
  } */


  @media (max-width: 576px) {
    .footer .link2 {
      font-size: 3vw;
      font-weight: 600;
      margin-top: 17px;
    }

    .footer .link2:hover::after {
      width: 0%;
    }

    .footer .copy{
      font-size: 3.2vw;
    }
  }
  .footer .link1:hover {
    color: #fff;
  }
  
  .footer .b:hover {
    color: #fff;
  }
  .footer .copy {
    
    font-weight: 500;
  }
  .footer .pow {
    position: absolute;
    height: 10rem;
    top: -6rem;
    right: 10%;
    z-index: 1;
    transition: .4s ease;
  }
  .footer .pow:hover {
    transform: translateY(-30px);
  
  }
  @media (max-width: 576px) {
    .footer .pow {
      height: unset;
      width: 30px;
      top: -60px;
      right: 8%;
    }

    .footer .pow:hover {
      transform: translateY(0px);
    
    }

    .footer .link1:hover {
      color: unset;
    }
  }
  