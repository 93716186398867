.heading {
  font-family: var(--AppFont);
  font-weight: bold;
  padding-left: 7px;
  color: var(--cmn-text-color-dark);
  border-left: 4px solid var(--cmn-text-color-dark);
}

@media (max-width: 576px) {
  .heading {
    padding-left: 5px;
    border-left: 5px solid var(--cmn-text-color-dark);
    font-size: calc(1.1rem + .3vw);
  }
}

.mainImg {
  transition: opacity 0.2s ease-in-out;
}

.fadeOut {
  opacity: 0.5;
}

.fadeIn {
  opacity: 1;
}

.smallIMGContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.smallIMG {
  width: 18.5%;
}

.dataDates {
  display: flex;
}

.dataDates p {
  font-family: var(--AppFont);
  color: var(--cmn-text-color-ash);
  font-weight: 500;
  margin-bottom: 20px;
}

@media (max-width: 576px) {
  .dataDates p {
    font-size: 0.8rem;
    margin: 13px 0px;
  }
}

.dataDates p:first-child {
  margin-right: 20px;
}

.table {
  width: 90%;
  margin-left: 10%;
}

@media (max-width: 576px) {
  .table {
    width: 100%;
    margin-left: 0%;
  }
}

.table tr {
  border-bottom: 2px solid var(--cmn-text-color-ash);
}

@media (max-width: 576px) {
  .table tr {
    border-bottom: 1px solid var(--cmn-text-color-ash);
  }
}

.table tr td:first-child {
  width: 35%;
  padding: 18px 20px 18px 50px;
  color: var(--cmn-text-color-ash);
  font-weight: bold;
  font-size: 1.1rem;
}

@media (max-width: 576px) {
  .table tr td:first-child {
    width: 40%;
    padding: 14px 0px 14px 25px;
    font-size: 0.8rem;
  }
}

.table tr td:last-child {
  width: 65%;
  color: var(--cmn-text-color-ash);
  font-weight: 500;
  font-size: 1.1rem;
}

@media (max-width: 576px) {
  .table tr td:last-child {
    width: 60%;
    font-size: 0.8rem;
  }
}

.rating {
  color: var(--cmn-golden-rod);
  font-family: var(--AppFont);
  font-size: 1.2rem;
  margin-left: 5px;
}

.address,
.handleBreeds {
  display: flex;
  justify-content: start;
  align-items: center;
}

.address span,
.handleBreeds span {
  font-family: var(--AppFont);
  position: relative;
  margin-right: 4px;
}

.address span::after {
  content: ">";
  margin-left: 2px;
}

.handleBreeds span::after {
  content: ",";
  margin-left: 2px;
  margin-right: 4px;
}

.address span:last-child::after {
  content: "";
}

.handleBreeds span:last-child::after {
  content: "";
}



.BG_GRAY {
  background-color: var(--cmn-gray-light);
  padding: 50px 0px;
  margin-top: 5%;
}

@media (max-width: 576px) {

  .BG_GRAY {
    padding: 30px 0px;
  }
}

.description {
  font-family: var(--AppFont);
  font-weight: 500;
  color: #666666;
  font-size: 1.1rem;
  margin-bottom: 0;
  padding: 10px;
}

@media (max-width: 576px) {
  .description {
    font-size: 0.9rem;
    padding: 0px;
  }
}

.mobileDescriptionCollaps {
  height: auto;
  max-height: 450px;
  overflow: hidden;
  position: relative;
  padding-bottom: 50px !important;
}

.mobileDescriptionUnCollaps {
  max-height: auto;
}

.expandButton {
  position: absolute;
  top: 360px;
  width: 100%;
  left: 0;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 36%, rgba(255, 255, 255, 0.3) 100%);
  text-align: center;
  height: 90px;
  padding-top: 50px;
}

.expandButton a {
  text-decoration: none;
  color: var(--cmn-text-color-ash);
}

.seeAllLink {
  font-family: var(--AppFont);
  text-align: end;
  margin-left: auto;
  display: flex;
  align-items: end;
  text-decoration: none;
  color: var(--cmn-text-color-ash);
  font-weight: 500;
}

.ProgressBarHolder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProgressBarHolder span {
  margin-right: 10px;
}



.RatingInfo {
  display: flex;
  justify-content: center;
}

.RatingInfo .details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10%;
}

.RatingInfo .details p:nth-child(1) {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
  color: #FFAD15;
  font-family: var(--AppFont);
  margin-bottom: 0;
}

.RatingInfo .details span {
  font-size: 1.8rem;
}

.RatingInfo .details .ratingTotalTXT {
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  color: var(--cmn-text-color-ash);
  font-family: var(--AppFont);
  margin-bottom: 0;
}

.loadMoreReviewButton {
  padding: 13px 25px;
  color: var(--cmn-text-color-ash);
  border: 0;
  outline: none;
  background-color: var(--cmn-gray-light);
  font-weight: 500;
  margin-top: 2%;
}

@media (max-width: 576px) {
  .loadMoreReviewButton {
    font-size: 0.9rem;
  }

  .RatingInfo .details span {
    font-size: 1.5rem;
  }
}

.buttonYellow {
  background-color: #FED858;
  padding: 13px 85px;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease;
}

@media (max-width: 576px) {
  .buttonYellow {
    width: 100%;
    font-size: 0.9rem;
    padding: 12px;
  }
}

.buttonYellow:hover {
  border: 2px solid #FED858;
  background-color: #fff;
  padding: 11px 85px;
}

.regInfoText {
  font-family: var(--AppFont);
  color: var(--cmn-text-color-ash);
  font-weight: 500;
}

.regInfo {
  width: 100%;
  background-color: #D3D3D3;
  padding: 2% 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 576px) {
  .regInfo {
    flex-direction: column;
    align-items: flex-start;
  }
}


.regInfo .div div {
  font-family: var(--AppFont);
  font-size: 0.95rem;
}

@media (max-width: 576px) {
  .regInfo .div div {
    font-size: 0.8rem;
    margin: 10px 0;
  }

}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}