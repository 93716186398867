.bg-pink {
  background-color: #f394b2;
}

.bg-green {
  background-color: #a2d7c6;
}

.bg-orange {
  background-color: #f69656;
}

.Veterinarian,
.Breeder {
  padding-top: 1.81vw;
}
@media (max-width: 576px) {
  .Veterinarian,
  .Breeder {
    padding-top: 50px;
  }
}
.Veterinarian .circle,
.Breeder .circle {
  width: 12vw;
  height: 12vw;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.8vw;
  cursor: pointer;
}
@media (max-width: 576px) {
  .Veterinarian .circle,
  .Breeder .circle {
    font-size: 0.9em;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    flex-direction: row;
    justify-content: start;
    margin-left: 8%;
    padding: 0px 0px 30px 28px;
    align-items: end;
  }
}
.Veterinarian .circle img,
.Breeder .circle img {
  height: 3vw;
}
@media (max-width: 576px) {
  .Veterinarian .circle img,
  .Breeder .circle img {
    height: 45px;
  }
}
.Veterinarian .circle .text,
.Breeder .circle .text {
  display: flex;
  flex-direction: row;
  align-self: baseline;
  padding-left: 2vw;
  align-items: center;
}
@media (max-width: 576px) {
  .Veterinarian .circle .text,
  .Breeder .circle .text {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
    align-self: flex-end;
    margin-bottom: -5px;
  }
}
.Veterinarian .circle .text .title,
.Breeder .circle .text .title {
  margin: 0;
  color: #101c46;
  text-align: start;
  font-size: 2.2vw;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: bold;
  margin-left: 0;
  white-space: nowrap;
  margin-right: 2vw;
  line-height: 2.8vw
}
@media (max-width: 576px) {
  .Veterinarian .circle .text .title,
  .Breeder .circle .text .title {
    font-size: 1.6em;
    margin-left: 8px;
    line-height: 1.2em;
  }
}
.Veterinarian .circle .text .subtitle,
.Breeder .circle .text .subtitle {
  margin: 0;
  color: #101c46;
  text-align: start;
  font-size: 1.2vw;
  font-weight: bold;
  margin-left: 0;
  white-space: nowrap;
  font-family: "Montserrat", sans-serif;
}
@media (max-width: 576px) {
  .Veterinarian .circle .text .subtitle,
  .Breeder .circle .text .subtitle {
    font-size: 0.8em;
    letter-spacing: 0.2em;
    margin-left: 8px;
  }
}
.Veterinarian .subtext,
.Breeder .subtext {
  margin-top: 5%;
  margin-left: 3vw;
  color: #101c46;
  text-align: start;
  font-size: 1.8vw;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  /* margin-left: 0; */
  white-space: nowrap;
}
@media (min-width: 1801px) {
  .Veterinarian .subtext,
  .Breeder .subtext {
    font-size: 1.5vw;
  }
}
@media (max-width: 576px) {
  .Veterinarian .subtext,
  .Breeder .subtext {
    margin-top: 10%;
    font-size: 1.4em;
    text-align: center;
  }
}
.Veterinarian .greenList,
.Veterinarian .pinkList,
.Veterinarian .orangeList,
.Breeder .greenList,
.Breeder .pinkList,
.Breeder .orangeList {
  margin-top: 10%;
  margin-left: 4.3vw;
}
.Veterinarian .greenList ul,
.Veterinarian .pinkList ul,
.Veterinarian .orangeList ul,
.Breeder .greenList ul,
.Breeder .pinkList ul,
.Breeder .orangeList ul {
  list-style-position: inside;
  text-indent: -2em;
  padding-left: 1em;
}
.Veterinarian .greenList li,
.Veterinarian .pinkList li,
.Veterinarian .orangeList li,
.Breeder .greenList li,
.Breeder .pinkList li,
.Breeder .orangeList li {
  list-style: none;
  font-size: 1.5vw;
  font-family: "Noto Sans JP";
  font-weight: 400;
  margin-top: 1.1vw;
  margin-left: 0px;
  margin-right: 0;
}
@media (min-width: 1801px) {
  .Veterinarian .greenList li,
  .Veterinarian .pinkList li,
  .Veterinarian .orangeList li,
  .Breeder .greenList li,
  .Breeder .pinkList li,
  .Breeder .orangeList li {
    font-size: 1.9em;
  }
}
@media (max-width: 576px) {
  .Veterinarian .greenList li,
  .Veterinarian .pinkList li,
  .Veterinarian .orangeList li,
  .Breeder .greenList li,
  .Breeder .pinkList li,
  .Breeder .orangeList li {
    font-size: 1.2em;
    margin-left: 17%;
    margin-right: 17%;
  }
  .Veterinarian .greenList,
  .Veterinarian .pinkList,
  .Veterinarian .orangeList,
  .Breeder .greenList,
  .Breeder .pinkList,
  .Breeder .orangeList {
    margin-left: 0;
  }
}
.Veterinarian .greenList li::before,
.Veterinarian .pinkList li::before,
.Veterinarian .orangeList li::before,
.Breeder .greenList li::before,
.Breeder .pinkList li::before,
.Breeder .orangeList li::before {
  content: "";
  display: inline-block;
  height: 2.2vw;
  width: 2.2vw;
  margin-right: 10px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateY(0.5vw);
}
@media (max-width: 576px) {
  .Veterinarian .greenList li::before,
  .Veterinarian .pinkList li::before,
  .Veterinarian .orangeList li::before,
  .Breeder .greenList li::before,
  .Breeder .pinkList li::before,
  .Breeder .orangeList li::before {
    height: 7vw;
    width: 7vw;
    position: relative;
    top: 7px;
    margin-right: 4px
  }
}
.Veterinarian .greenList li,
.Breeder .greenList li {
  color: #4caa8b;
}
.Veterinarian .greenList li::before,
.Breeder .greenList li::before {
  background-image: url(./img/greentick.png);
}
.Veterinarian .pinkList li,
.Breeder .pinkList li {
  color: #df537f;
}
.Veterinarian .pinkList li::before,
.Breeder .pinkList li::before {
  background-image: url(./img/pinktick.png);
}
.Veterinarian .orangeList li,
.Breeder .orangeList li {
  color: #e56e49;
}
.Veterinarian .orangeList li::before,
.Breeder .orangeList li::before {
  background-image: url(./img/orangetick.png);
}
.Veterinarian .d-img,
.Breeder .d-img {
  width: 100%;
  height: auto;
}
@media (max-width: 576px) {
  .Veterinarian .d-img,
  .Breeder .d-img {
    /* height: 60vw; */
  }
}
.Veterinarian .path,
.Breeder .path {
  margin-top: 15%;
}
.Veterinarian .path .pathBtn,
.Breeder .path .pathBtn {
  font-size: 1.5vw;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: bold;
  color: #101c46;
  text-decoration: none;
  background-color: #fff;
  border-radius: 1.5vw;
  text-align: center;
  padding: 0.9vw 1vw;
  width: 20vw;
  border: 2px solid #101c46;;
  margin: 1vw;
  transition: .4s ease;
}
@media (max-width: 576px) {
  .Veterinarian .path .pathBtn,
  .Breeder .path .pathBtn {
    font-size: 1.2em;
    width: 220px;
    border-radius: 10px;
    padding: 2vw 1vw;
    margin: 3vw;
  }
}


.Veterinarian .path .pathBtn:hover,
  .Breeder .path .pathBtn:hover {
    color: #fff;
    background-color: #6080bb;
  
  }
