video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

#videoContainer {
  max-width: 100%;
  width: 600px;
  height: 300px;
  position: relative;
  overflow: hidden;
  padding: 0;
}
#soundContainer{
  width: 600px;
  display: block;
}
#cameraMasking{
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1030;
}
@media (max-width: 768px) {
  #videoContainer {
    width: 600px;
    height: 300px;
  }
  #soundContainer{
    width: 600px;
  }
}
@media screen and (max-width: 576px) {
  #videoContainer {
    width: 100%;
    height: 200px;
  }
  #soundContainer{
    width: 100%;
  }

}
